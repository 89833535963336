export enum FormInstanceType {
  known = 0,
  anonymous = 1,
  practice = 2,
}

export enum QuestionTypeNumberEnum {
  Text = 1,
  Numeric = 2,
  Date = 3,
  Enum = 4,
  Range = 5,
}
