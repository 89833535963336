import { AfterViewInit, Component, Input, ViewChildren } from "@angular/core";
import { QuestionRange } from "src/app/main/model/forms/question-range.model";
import { FormCompletionBaseComponent } from "../form-completion-base.component";

@Component({
  selector: "app-question-range",
  templateUrl: "./question-range.component.html",
  styleUrls: ["./question-range.component.scss"],
})
export class QuestionRangeComponent extends FormCompletionBaseComponent implements AfterViewInit {
  @ViewChildren("input") inputRefs;

  @Input() public question: QuestionRange;

  public currentValue;

  ngAfterViewInit() {
    for (let i = 0; i < this.inputRefs._results.length; i++) {
      this.inputRefs._results[i].nativeElement.name = this.question.getName();
    }
  }

  public selectOption(value: number): void {
    if (this.service) {
      this.control.setValue(value);
    } else {
      this.currentValue = value;
    }
  }

  public get control() {
    return this.service.formGroup.controls[this.question.getName()];
  }

  public get useIcons(): boolean {
    return !!this.question.viewMeta?.useIcons;
  }
}
