import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { NotificationsService } from "angular2-notifications";
import * as _ from "lodash";
import { get } from "lodash-es";
import { lastValueFrom } from "rxjs";
import {
  API_ASSOCIATIONS,
  FORMGROUPCOMPANY_AND_BENCHMARK_JSON_PATH,
} from "src/common/constants/api.constants";
import { TAPIListResult, TAPIQuery } from "src/common/contracts/api.contracts";
import { apiCallWrapper } from "../../api/api.util";
import { FormGroupCompanyApi } from "../../api/forms/form-group-company.api";
import { HasIdOrExternalValueOrExternalIdOrServiceName } from "../../api/generic.api";
import { FormGroupCompany } from "../../model/forms/form-group-company.model";
import { IDBInclude, IQueryFilter } from "../../model/query.filter.class";
import { BaseService } from "../base.service";

@Injectable({
  providedIn: "root",
})
export class FormGroupCompanyService extends BaseService {
  constructor(
    private notifications: NotificationsService,
    private formGroupCompanyApi: FormGroupCompanyApi,
  ) {
    super();
  }

  /**
   * @returns a paginated list of FormGroupCompany and benchmarks data
   */
  async getFormGroupCompanyByBenchmarkId(
    id: number,
    limit?: number, //TODO: Fix this fudge to get around the fact the API_ASSOCIATIONS.formGroupCompanyAndBenchmark doesn't always return data
    // database is being called with limit in the inner query instead of around the entire query.  Outstanding question raised with Steele

    queryParams?: TAPIQuery,
  ): Promise<TAPIListResult<FormGroupCompany>> {
    try {
      const componentQuery = this.transformQueryParamsToIQueryFilter(queryParams);
      if (limit) componentQuery.limit = limit;

      const defaultQuery: IQueryFilter = new IQueryFilter(
        _.cloneDeep(API_ASSOCIATIONS.formGroupCompanyAndBenchmark),
      );

      const benchmarkInclude: IDBInclude | undefined = get(
        defaultQuery,
        FORMGROUPCOMPANY_AND_BENCHMARK_JSON_PATH,
      ) as unknown as IDBInclude;
      if (benchmarkInclude) {
        benchmarkInclude.filter = { id };
      } else {
        Sentry.captureException(
          new Error(
            "FORMGROUPCOMPANY_AND_BENCHMARK_JSON_PATH not found in API_ASSOCIATIONS.formGroupCompanyAndBenchmark",
          ),
        );
        throw "Failed to get Benchmark Organisation data";
      }

      return await this._getFormGroupCompany(this.mergeQueryFilters(componentQuery, defaultQuery));
    } catch (error) {
      throw error;
    }
  }

  /**
   * @description Get the signed logo URL for the company
   * @param id company id
   * @returns
   */
  async getLogoSignedUrl(id: number): Promise<string> {
    try {
      return await lastValueFrom(
        apiCallWrapper(this.formGroupCompanyApi.getLogoSignedUrl(id), {
          notificationsService: this.notifications,
          action: "Obtaining logo signed URL",
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  private async _getFormGroupCompany(
    query: IQueryFilter,
  ): Promise<TAPIListResult<FormGroupCompany>> {
    try {
      const data = await lastValueFrom(
        apiCallWrapper(this.formGroupCompanyApi.list(query), {
          notificationsService: this.notifications,
          action: "Obtaining FormGroupCompany data",
        }),
      );

      return this.inflateModel<
        FormGroupCompany,
        FormGroupCompany & HasIdOrExternalValueOrExternalIdOrServiceName<string>
      >(FormGroupCompany, data);
    } catch (error) {
      throw error;
    }
  }
}
