import { Component, Input } from "@angular/core";
import { FormTemplateQuestion } from "src/app/main/model/forms/form-template-question.model";
import { FormTemplateSection } from "src/app/main/model/forms/form-template-section.model";
import { hasKey } from "src/app/main/util/object.util";

@Component({ template: "" })
export class FormTemplateSectionBaseComponent {
  @Input() public formTemplateSection: FormTemplateSection[];

  public isFormTemplateSection(
    question: FormTemplateQuestion | FormTemplateSection,
  ): question is FormTemplateSection {
    return hasKey(question, "parentFormTemplateSectionId");
  }
}
