import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { DocumentTypeEnum } from "../document/contracts/document-type.contract";
import { Document } from "../document/document.model";
import { FormTemplateGroup } from "./form-template-group.model";
import { FormTemplate } from "./form-template.model";

export class FormGroup extends BaseModel {
  id: number;
  name: string;
  internalName: string;
  description: string;
  @Type(() => Boolean)
  isMSF: boolean;
  @Type(() => Boolean)
  isDraft: boolean;
  @Type(() => Boolean)
  isArchived: boolean;
  @Type(() => FormTemplateGroup)
  formTemplateGroups: FormTemplateGroup[];
  @Type(() => Document)
  documents: Document[];

  public getFormTemplates(): FormTemplate[] {
    if (this.formTemplateGroups && this.formTemplateGroups.length > 0) {
      return this.formTemplateGroups.map(
        (formTemplateGroup: FormTemplateGroup) => formTemplateGroup.formTemplate,
      );
    } else {
      return [];
    }
  }

  public getFormDocuments(documentTypes: DocumentTypeEnum[]): Document[] {
    if (this.documents && this.documents.length > 0) {
      return this.documents.filter((document: Document) =>
        documentTypes.includes(document.documentType.id),
      );
    } else {
      return [];
    }
  }

  /**
   * @description works out the sum of all minimum number of responses required for all form templates in this form group
   * @returns
   */
  get minResponsesForFullAcrossAllTemplates(): number {
    return this.formTemplateGroups.reduce((sum, formTemplateGroup) => {
      return sum + formTemplateGroup.formTemplate.minResponsesForFull;
    }, 0);
  }

  /**
   * @description works out the sum of all minimum number of responses required for all form templates in this form group
   * @returns
   */
  get minResponsesForAbrvFullAcrossAllTemplates(): number {
    return this.formTemplateGroups.reduce((sum, formTemplateGroup) => {
      return sum + formTemplateGroup.formTemplate.minResponsesForAbrv;
    }, 0);
  }
}
