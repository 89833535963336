import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const slideInAnimation = trigger("routeAnimations", [
  transition("* <=> Home", [
    transition(":enter", [style({ opacity: 0 }), animate(1000, style({ opacity: 1 }))]),
    transition(":leave", [style({ opacity: 1 }), animate(1000, style({ opacity: 0 }))]),
  ]),

  transition("* <=> *", [
    style({ position: "relative" }),
    /*query('footer', [
          style({ opacity: 0 })
      ]),
      */
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }),
      ],
      { optional: true },
    ),
    query(":enter", [style({ left: "-100%" })], { optional: true }),
    query(":leave", animateChild(), { optional: true }),
    group([
      query(":leave", [animate("200ms ease-out", style({ left: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("300ms ease-out", style({ left: "0%" }))], {
        optional: true,
      }),
    ]),
    query(":enter", animateChild(), { optional: true }),
  ]),
]);
