import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Transform, Type } from "class-transformer";
import { FormAnswer, FormAnswerObject } from "./form-answer.model";
import { QuestionVariant } from "./question-variant.model";

export enum QuestionTypeEnum {
  List = "List",
  Text = "Text",
  Numeric = "Numeric",
  Date = "Date",
  Range = "Range",
  ShortText = "Short text",
}

export abstract class FormTemplateQuestion {
  id: number;
  order: number;
  // https://www.notion.so/FormTemplate-viewMeta-fb305e5f930a42f097e998cf88cbed6f
  viewMeta: {
    hideOrder?: boolean;
    useIcons?: boolean;
    width?: number;
    height?: number;
  } | null;

  @Type(() => Boolean)
  required: boolean;

  @Transform(({ value }) => decodeURIComponent(value || ""))
  value: string;
  description: string;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  note: string;
  defaultValue: string;
  type: QuestionTypeEnum;
  @Type(() => QuestionVariant)
  questionVariant: QuestionVariant;

  public static isQuestionRange(question: FormTemplateQuestion): boolean {
    return question.type === QuestionTypeEnum.Range;
  }
  public static isQuestionList(question: FormTemplateQuestion): boolean {
    return question.type === QuestionTypeEnum.List;
  }
  public static isQuestionText(question: FormTemplateQuestion): boolean {
    return question.type === QuestionTypeEnum.Text || question.type === QuestionTypeEnum.ShortText;
  }

  /**
   * Collects FormControls to construct a new @angular/forms FromGroup instance
   * - Online questionnaires: no answers are provided, default value initialised with questions defaultValue if any
   * - Response verification: submitted answers are provided, populates the control default value if exist for this question
   * @param answersToDefaultTo
   * @returns FormGroup controls
   */
  public collectFormControl(answersToDefaultTo: FormAnswerObject): Record<string, FormControl> {
    let defaultValue = this.defaultValue;
    if (answersToDefaultTo[this.id] !== undefined) {
      const editedAnswer = answersToDefaultTo[this.id][this.getAnswerDataField(true)[0]];
      const originalAnswer = answersToDefaultTo[this.id][this.getAnswerDataField(false)[0]];
      defaultValue = editedAnswer !== "" ? editedAnswer || originalAnswer : editedAnswer;
    }
    return {
      [this.getName()]: new FormControl(
        defaultValue,
        this.required ? Validators.required : Validators.nullValidator,
      ),
    };
  }

  public getName() {
    return "q" + this.id;
  }

  /**
   * Gets the data field to read from a FormAnswer depending on the type of question
   * @param isEdit
   * @returns a list of field names used by this type of question
   */
  public getAnswerDataField(isEdit = false): string[] {
    if (FormTemplateQuestion.isQuestionRange(this)) {
      return [isEdit ? "editedRangeData" : "rangeData"];
    }
    if (FormTemplateQuestion.isQuestionList(this)) {
      return [isEdit ? "editedEnumData" : "enumData", isEdit ? "editedStringData" : "stringData"];
    }
    if (FormTemplateQuestion.isQuestionText(this)) {
      return [isEdit ? "editedStringData" : "stringData"];
    }
    return ["stringData"];
  }

  /**
   * Given a previous submission answers, checks if there is an answer for this question.
   * If so, returns the data that is recorded on submission (default) or the edited response (isEdit = true).
   * For range / enum questions, an additional "field" can be provided, to return "label" or "value"
   * @param answers
   * @param isEdit
   * @param field
   */
  abstract readDataFromAnswers(
    answers: FormAnswerObject,
    isEdit?: boolean,
    field?: string,
  ): string | number;

  abstract collectAnswer(formGroup: FormGroup, isEdit: boolean): FormAnswer[] | null;
}
