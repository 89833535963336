<ul
  [formGroup]="service.formGroup"
  class="{{ class }} {{
    'column-count-' +
      (question.questionEnum.enumValues.length >= 6
        ? roundUp(question.questionEnum.enumValues.length / 3)
        : '')
  }}"
>
  <li *ngFor="let enumValue of question.questionEnum.enumValues" class="enum-option">
    <div (click)="selectOption(enumValue)" class="enum-option-selectable">
      <input
        #input
        type="radio"
        [checked]="currentValue.includes(enumValue.value)"
        [value]="enumValue.value"
      />
      <span class="ms-0 me-2">{{ enumValue.label }}</span>
      <input
        class="w-100"
        *ngIf="enumValue.isText"
        (keyup)="onTextChange($event, enumValue)"
        [value]="currentText"
        tabIndex="{{ currentValue.includes(enumValue.value) ? 0 : -1 }}"
      />
    </div>
  </li>
</ul>
