import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import * as Sentry from "@sentry/angular";
import { SimpleNotificationsModule } from "angular2-notifications";
import { ToastrModule } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { JwtInterceptor } from "./interceptor/jwt.interceptor";
import { ErrorHandlerService } from "./services/error/error-handler.service";
import { ModalService } from "./services/modal.service";
import { MainTemplateComponent } from "./template/main-template/main-template.component";
import { OptOutComponent } from "./view/admin-client/opt-out/opt-out.component";
import { StopAutoEmailsComponent } from "./view/admin-client/stop-auto-emails/stop-auto-emails.component";
import { ForgotPasswordComponent } from "./view/auth/forgot-password/forgot-password.component";
import { LoginComponent } from "./view/auth/login/login.component";
import { ResetPasswordComponent } from "./view/auth/reset-password/reset-password.component";
import { AdminDashboardComponent } from "./view/dashboard-admin/admin-dashboard/admin-dashboard.component";
import { CorporateReportingComponent } from "./view/dashboard-admin/corporate-reporting/corporate-reporting.component";
import { ClientDashboardComponent } from "./view/dashboard-client/client-dashboard/client-dashboard.component";
import { DRODashboardComponent } from "./view/dashboard-dro/dro-dashboard/dro-dashboard.component";
import { GenerateBenchmarkComponent } from "./view/dashboard-dro/generate-benchmark/generate-benchmark.component";
import { EditSurveyComponent } from "./view/dashboard/edit-survey/edit-survey.component";
import { ReviewCommentsScansComponent } from "./view/dashboard/review-comments-scans/review-comments-scans.component";
import { DashboardComponent } from "./view/dashboard/survey-dashboard/survey-dashboard.component";
import { ViewDealComponent } from "./view/dashboard/view-deal/view-deal.component";
import { ViewResponseComponent } from "./view/dashboard/view-response/view-response.component";
import { ViewResponsesComponent } from "./view/dashboard/view-responses/view-responses.component";
import { NotFoundComponent } from "./view/error/not-found/not-found.component";
import { OnlineFormComponent } from "./view/online-form/online-form.component";
import { EditColleaguesComponent } from "./view/shared/edit-colleagues/edit-colleagues.component";
import { SharedModule } from "./view/shared/shared.module";

const MATERIAL_MODULES = [
  MatIconModule,
  MatTableModule,
  MatInputModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatSortModule,
];

@NgModule({
  declarations: [
    AdminDashboardComponent,
    GenerateBenchmarkComponent,
    DRODashboardComponent,
    EditColleaguesComponent,
    ClientDashboardComponent,
    CorporateReportingComponent,
    DashboardComponent,
    EditSurveyComponent,
    ForgotPasswordComponent,
    LoginComponent,
    MainTemplateComponent,
    NotFoundComponent,
    OnlineFormComponent,
    OptOutComponent,
    ResetPasswordComponent,
    StopAutoEmailsComponent,
    ViewDealComponent,
    ViewResponseComponent,
    ViewResponsesComponent,
    ReviewCommentsScansComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    HttpClientModule,
    ...MATERIAL_MODULES,
    NgbModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.enableServiceWorker,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    SharedModule,
    SimpleNotificationsModule.forRoot(),
    ToastrModule.forRoot({ positionClass: "toaster-fixed" }),
    /* Alerts - https://www.npmjs.com/package/angular2-notifications */
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "en-AU" }, //displays date in dd/mm/yyyy format in mat date picker
    ModalService,

    /** Sentry */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService, ModalService],
      multi: true,
    },
  ],
  bootstrap: [MainTemplateComponent],
})
export class AppModule {}
