import { Transform, Type } from "class-transformer";
import { FormAnswer } from "./form-answer.model";
import { Reviewable } from "./reviewable.model";

export class FormResponse extends Reviewable {
  id: number;
  formInstanceId: number;
  isOnline: boolean;
  submissionUUID: string;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  notes: string;
  isManuallyAdded?: boolean;
  @Type(() => FormAnswer)
  formAnswers: FormAnswer[];
}

export type FormResponseEdit = {
  id: number;
  formInstanceId: number;
  isOnline?: boolean;
  submissionUUID: string;
  notes?: string;
  reviewedByUserId?: string;
  reviewedOn?: Date;
  isManuallyAdded?: boolean;
  formAnswers: FormAnswer[];
};
