<app-public-header></app-public-header>

<div *ngIf="!isRequestSubmitted; then form; else thankYou"></div>

<ng-template #form>
  <div class="welcome-wrap">
    <h3>Forgot your password?</h3>
    <p>No problem. Submit your email below to reset your password.</p>
    <form
      class="row g-3"
      #form="ngForm"
      [formGroup]="forgotPasswordForm"
      novalidate
      (submit)="onSubmit()"
    >
      <div class="col-md-12">
        <input
          class="form-control"
          [ngClass]="{
            'is-invalid': (form.submitted || f.email.touched) && f.email.invalid,
            'is-valid': f.email.valid
          }"
          id="email"
          type="email"
          placeholder="Enter your email"
          formControlName="email"
          aria-label="Enter email"
        />
        <div
          *ngIf="f.email.invalid && ((f.email.dirty && f.email.touched) || form.submitted)"
          class="invalid-feedback"
        >
          Invalid email address
        </div>
      </div>
      <div class="col-12">
        <button type="submit" class="btn btn-green">Submit</button>
      </div>
      <span>Go back to <a [routerLink]="'/login'">Login here</a></span>
    </form>
  </div>
</ng-template>

<ng-template #thankYou>
  <div class="welcome-wrap">
    <p>
      Password reset email will be sent to {{ f.email.value }}. Check your inbox for prompts to
      change your password.
    </p>
    <div class="text-center">
      <span>Go back to <a [routerLink]="'/login'">Login here</a></span>
    </div>
  </div>
</ng-template>

<app-public-footer></app-public-footer>
