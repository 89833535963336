<div class="btn-nxtprv mt-0 gap-0 mb-3">
  <button class="btn-nxt" (click)="service.restartFormInstance()">Restart from Beginning</button>
</div>
<div class="progress-bar-wrap">
  <div class="progress">
    <div
      class="progress-bar"
      [ngStyle]="{ width: ((currentSlideIndex + 1) * 100) / slides.length + '%' }"
    >
      <div class="step">{{ currentSlideIndex + 1 + " of " + slides.length }}</div>
    </div>
  </div>
</div>
<app-form-completion-template-section [formTemplateSection]="slides[currentSlideIndex]" />
<div class="btn-nxtprv">
  <button
    class="btn-bck d-flex align-items-center"
    type="button"
    *ngIf="currentSlideIndex > 0"
    (click)="goPreviousSlide()"
  >
    <mat-icon svgIcon="arrow-left" class="mat-icon-size-16 ms-1" />
    Previous slide
  </button>
  <!-- If all buttons in this div use *ngIf they become unclickable on mobile. I suspect the container doesn't get the right height.
  Changing one button *ngIf to [class.d-none] avoid the issue, but using [class.d-none] on all buttons creates the issue...
  Most strange of all, when starting remote debugging, the buttons work again on the mobile!
  https://developer.chrome.com/docs/devtools/remote-debugging/ -->
  <button
    class="btn-nxt d-flex align-items-center"
    [class.d-none]="currentSlideIndex === slides.length - 1"
    type="button"
    (click)="validateSection()"
  >
    Next slide
    <mat-icon svgIcon="arrow-right" class="mat-icon-size-16" />
  </button>
  <button class="submit-btn" type="submit" *ngIf="currentSlideIndex === slides.length - 1">
    Submit
  </button>
</div>
