import { Component, Input } from "@angular/core";
import { FormCompletionService } from "src/app/main/services/form-completion/form-completion.service";

@Component({ template: "" })
export class FormCompletionBaseComponent {
  @Input() class: string;

  constructor(private formCompletionService: FormCompletionService) {}

  // helpers
  public get service() {
    return this.formCompletionService;
  }
}
