<ng-container [formGroup]="service.formGroup">
  <textarea
    *ngIf="question.type === questionType.Text"
    class="form-control"
    rows="3"
    [maxLength]="question.maxLength"
    [formControlName]="question.getName()"
  ></textarea>
  <input *ngIf="question.type === questionType.ShortText" [formControlName]="question.getName()" />
</ng-container>
