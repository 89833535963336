import { Component, Input } from "@angular/core";
import { FormCompletionService } from "src/app/main/services/form-completion/form-completion.service";
import { FormTemplateQuestionBaseComponent } from "../../form-template-base/form-template-question-base.component";

@Component({
  selector: "[app-form-completion-template-question]",
  templateUrl: "./form-completion-template-question.component.html",
  styleUrls: ["./form-completion-template-question.component.scss"],
})
export class FormCompletionTemplateQuestionComponent extends FormTemplateQuestionBaseComponent {
  @Input() public isGroupedQuestion: boolean;

  constructor(private formCompletionService: FormCompletionService) {
    super();
  }

  // helpers
  public get service() {
    return this.formCompletionService;
  }
  public get formControl() {
    return this.service.formGroup.controls[this.formTemplateQuestion.getName()];
  }
  public get hideOrder() {
    return !!this.formTemplateQuestion.viewMeta?.hideOrder;
  }
}
