import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TResultAndMessage } from "src/common/contracts/api.contracts";
import { FormGroupInstance as FormInstanceContact } from "../../model/forms/form-group-instance.model";
import { createUrl } from "../api.util";
import { GenericApi } from "../generic.api";

// Convenience Type
type T = FormInstanceContact;
type I = FormInstanceContact;

@Injectable({
  providedIn: "root",
})
export class FormInstanceContactApi extends GenericApi<T> {
  public path = "form-instance-contact";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  readonly optOut = (uuid: string): Observable<TResultAndMessage> => {
    return this.httpClient.put<TResultAndMessage>(createUrl(this.path, "opt-out", uuid), {});
  };

  readonly stopAutoEmails = (uuid: string): Observable<TResultAndMessage> => {
    return this.httpClient.put<TResultAndMessage>(createUrl(this.path, "stop", uuid), {});
  };
}
