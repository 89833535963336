import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FormResponseEdit } from "../model/forms/form-response.model";
import { IMergeItem, MergeResult } from "../model/forms/merge-result.model";
import { HasId } from "../model/generics";
import { createUrl, getPublicRoutesHeaders } from "./api.util";
import { GenericApi } from "./generic.api";

// Convenience Type
type T = FormResponseEdit;
type I = T;

@Injectable({
  providedIn: "root",
})
export class FormResponseApi extends GenericApi<T, I> {
  public path = "form-response";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  create = (model: Omit<T, "id">): Observable<T & HasId<I>> => {
    return this.httpClient.post<T & HasId<I>>(createUrl(this.path), model, {
      headers: getPublicRoutesHeaders(),
    });
  };

  readScansLocations = (id: number | string): Observable<string[]> => {
    return this.httpClient.get<string[]>(createUrl(this.path, id, "scans"));
  };

  merge = (mergeInput: {
    mergeItems: IMergeItem[];
    isPreview: boolean;
  }): Observable<MergeResult[]> => {
    return this.httpClient.post<MergeResult[]>(createUrl(this.path, "merge"), mergeInput);
  };
}
