import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  IAuthPayload,
  IForgotPayload,
  IForgotResponse,
  IJWTPayload,
  IResetPayload,
  IResetResponse,
  IValidateTokenPayload,
  IValidateTokenResponse,
} from "../../model/auth/auth.model";
import { createUrl, getPublicRoutesHeaders } from "../api.util";

@Injectable({
  providedIn: "root",
})
export class AuthApi {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * @description Resets the authentication by making a POST request to the reset endpoint.
   * @param {IResetPayload} opts - The payload object for the reset request.
   * @returns {Promise<IResetResponse>} - A promise that resolves to the reset response object.
   * @readonly
   * @example
   * ```
   * const resetPayload = {
   *   username: 'john@example.com',
   *   password: 'newPassword'
   * };
   *
   * auth.reset(resetPayload)
   *   .then(response => {
   *     console.log(response); // The reset response object
   *   })
   *   .catch(error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  readonly reset = (opts: IResetPayload) => {
    return this.httpClient.post<IResetResponse>(createUrl("user", "reset-password"), opts, {
      headers: getPublicRoutesHeaders(),
    });
  };

  /**
   * @description Authenticates the user by making a POST request to the authentication endpoint.
   * @param {IAuthPayload} opts - The payload object for the authentication request.
   * @returns {Promise<IJWTPayload>} - A promise that resolves to the JWT payload object upon successful authentication.
   * @readonly
   * @example
   * ```
   * const authPayload = {
   *   username: 'john@example.com',
   *   password: 'password123'
   * };
   *
   * auth.authenticate(authPayload)
   *   .then(jwtPayload => {
   *     console.log(jwtPayload); // The JWT payload object
   *   })
   *   .catch(error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  readonly authenticate = (opts: IAuthPayload) => {
    return this.httpClient.post<IJWTPayload>(createUrl("user/login"), opts, {
      headers: getPublicRoutesHeaders(),
    });
  };

  /**
   * @description Sends a forgot password request by making a POST request to the forgot password endpoint.
   * @param {IForgotPayload} opts - The payload object for the forgot password request.
   * @returns {Promise<IForgotResponse>} - A promise that resolves to the response object indicating the success of the forgot password request.
   * @readonly
   * @example
   * ```
   * const forgotPayload = {
   *   email: 'john@example.com'
   * };
   *
   * auth.forgot(forgotPayload)
   *   .then(response => {
   *     console.log(response); // The response object indicating the success of the forgot password request
   *   })
   *   .catch(error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  readonly forgot = (opts: IForgotPayload) => {
    return this.httpClient.post<IForgotResponse>(createUrl("user", "forgot-password"), opts, {
      headers: getPublicRoutesHeaders(),
    });
  };

  /**
   * @description Refreshes the authentication token by making a POST request to the refresh endpoint.
   * @param {Omit<IJWTPayload, 'tokenType'>} opts - The payload object for the refresh request, excluding the token type.
   * @returns {Observable<IJWTPayload>} - An observable that emits the refreshed JWT payload upon successful token refresh.
   * @readonly
   * @example
   * ```
   * const refreshPayload = {
   *   refreshToken: 'abc123'
   * };
   *
   * auth.refresh(refreshPayload)
   *   .subscribe(jwtPayload => {
   *     console.log(jwtPayload); // The refreshed JWT payload
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  readonly refresh = (opts: Omit<IJWTPayload, "tokenType">): Observable<IJWTPayload> => {
    return this.httpClient.post<IJWTPayload>(createUrl("user", "refresh"), opts, {
      headers: getPublicRoutesHeaders(),
    });
  };

  readonly validateToken = (
    opts: Omit<IValidateTokenPayload, "secret">,
  ): Observable<IValidateTokenResponse> => {
    return this.httpClient.post<IValidateTokenResponse>(createUrl("validate-token"), opts, {
      headers: getPublicRoutesHeaders(),
    });
  };
}
