import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { Workflow } from "./workflow.model";

export class WorkflowStep extends BaseModel {
  id: number;
  name: string;
  description: string;
  order: number;
  @Type(() => Boolean)
  canMoveBack: Boolean;
  @Type(() => Workflow)
  workflow: Workflow;
}
