import { BaseModel } from "../../base.model";

export class IdentifiableContact extends BaseModel {
  id: string;
  externalId: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;

  /**
   * @param includeTitle true if you want to include the title in the full name
   * @returns  Title(conditional) + firstName + lastName
   */
  public contactLabel(includeTitle?: boolean): string {
    return `${includeTitle && this.title ? this.title + " " : ""}${this.fullName}`;
  }

  /**
   * @returns firstName + lastname
   */
  get fullName() {
    return `${!!this.firstName ? this.firstName + " " : ""}${!!this.lastName ? this.lastName : ""}`;
  }

  /**
   * @returns (firstName + lastname) || email
   */
  get contactIdentity() {
    return this.fullName || this.email;
  }
}
