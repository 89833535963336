<div *ngFor="let formTemplateSectionPart of formTemplateSection; index as j">
  <ng-container *ngIf="isSubSection">
    <div class="title" [innerHTML]="formTemplateSectionPart.value"></div>
    <div class="qus-wrap">
      <ng-container *ngFor="let question of formTemplateSectionPart.formTemplateQuestions">
        <ng-container *ngIf="!isFormTemplateSection(question)">
          <fieldset
            app-form-completion-template-question
            [formTemplateQuestion]="question"
            [isGroupedQuestion]="true"
          ></fieldset>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="!isSubSection">
    <ng-container *ngIf="j === 0">
      <h2 *ngIf="formTemplateSectionPart.value" [innerHTML]="formTemplateSectionPart.value"></h2>
    </ng-container>
    <ng-container *ngIf="j > 0 && formTemplateSectionPart.value !== '<FORCE_SECTION>'">
      <div class="txt">
        <p [innerHTML]="formTemplateSectionPart.value"></p>
      </div>
    </ng-container>
    <div
      class="form-section"
      [ngClass]="contentDirection(formTemplateSectionPart)"
      [class.check-wrap]="contentDirection(formTemplateSectionPart) === sectionDirection.Horizontal"
    >
      <ng-container *ngFor="let question of formTemplateSectionPart.formTemplateQuestions">
        <ng-container *ngIf="!isFormTemplateSection(question)">
          <fieldset
            app-form-completion-template-question
            [ngClass]="questionContainer(question)"
            [formTemplateQuestion]="question"
          ></fieldset>
        </ng-container>
        <ng-container *ngIf="isFormTemplateSection(question)">
          <ng-container
            *ngTemplateOutlet="subSectionTemplate; context: { formTemplateSection: question }"
          ></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #subSectionTemplate let-formTemplateSection="formTemplateSection">
  <app-form-completion-template-section
    [formTemplateSection]="[formTemplateSection]"
    [isSubSection]="true"
  />
</ng-template>
