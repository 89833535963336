import { ViewportScroller } from "@angular/common";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import {
  FormTemplateSection,
  FormTemplateSectionType,
} from "src/app/main/model/forms/form-template-section.model";
import { FormTemplate } from "src/app/main/model/forms/form-template.model";

@Component({ template: "" })
export class FormTemplateBaseComponent implements OnChanges {
  @Input() public formTemplate: FormTemplate;

  public slides: FormTemplateSection[][];
  public currentSlideIndex = 0;

  constructor(protected viewPortScroller: ViewportScroller) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes["formTemplate"] && changes["formTemplate"].currentValue) {
      this._initSlides();
    }
  }

  public goPreviousSlide() {
    this.currentSlideIndex--;
    this.viewPortScroller.scrollToPosition([0, 0]);
  }

  public goNextSlide() {
    this.currentSlideIndex++;
    this.viewPortScroller.scrollToPosition([0, 0]);
  }

  private _initSlides() {
    this.formTemplate.formTemplateSections
      .filter(
        (section) =>
          section.formTemplateSectionType.name !== FormTemplateSectionType.Introduction &&
          section.formTemplateQuestions.length,
      )
      .forEach((section, i) => {
        if (i === 0) {
          this.slides = [[section]];
        } else {
          if (section.viewMeta?.noPageBreaker) {
            this.slides[this.slides.length - 1].push(section);
          } else {
            this.slides[this.slides.length] = [section];
          }
        }
      });
  }
}
