import { Type } from "class-transformer";
import { BaseModel } from "../base.model";

export class SpecialityLevel extends BaseModel {
  value: string;
  label: string;
  @Type(() => Boolean)
  hidden: boolean;
  displayOrder: number;
}
