<app-public-header></app-public-header>

<div class="welcome-wrap">
  <h3>Reset password</h3>
  <form
    class="row g-3"
    #form="ngForm"
    [formGroup]="resetPasswordForm"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="col-md-12">
      <input
        class="form-control"
        [ngClass]="{
          'is-invalid': (form.submitted || f.password.touched) && f.password.invalid,
          'is-valid': f.password.valid
        }"
        id="password"
        type="password"
        placeholder="Enter New Password"
        formControlName="password"
        aria-label="Enter password"
      />
      <div
        *ngIf="f.password.invalid && ((f.password.dirty && f.password.touched) || form.submitted)"
        class="invalid-feedback"
      >
        Password must be at least 8 characters long and contain at least one uppercase letter and
        one symbol
      </div>
    </div>
    <div class="col-md-12">
      <input
        class="form-control"
        [ngClass]="{
          'is-invalid': (form.submitted || f.confirmPassword.touched) && f.confirmPassword.invalid,
          'is-valid': f.confirmPassword.valid
        }"
        id="confirmPassword"
        type="password"
        placeholder="Confirm New Password"
        formControlName="confirmPassword"
        aria-label="Confirm password"
      />
      <div
        *ngIf="
          f.confirmPassword.invalid &&
          ((f.confirmPassword.dirty && f.confirmPassword.touched) || form.submitted)
        "
        class="invalid-feedback"
      >
        The passwords don't match
      </div>
    </div>
    <div class="col-12">
      <button type="submit" class="btn btn-green">Submit</button>
    </div>
  </form>
</div>
<app-public-footer></app-public-footer>
