import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { NotificationsService } from "angular2-notifications";
import { plainToClass } from "class-transformer";
import * as _ from "lodash";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { API_ASSOCIATIONS } from "src/common/constants/api.constants";
import { apiCallWrapper } from "../../api/api.util";
import { UserApi } from "../../api/auth/user.api";
import { User } from "../../model/auth/user.model";
import { IQueryFilter } from "../../model/query.filter.class";

@Injectable({
  providedIn: "root",
})
/**
 * @description This service is used to manage user API.
 */
export class UserService {
  public currentUserSubject = new BehaviorSubject<User | null>(null);

  constructor(
    private notifications: NotificationsService,
    private userApi: UserApi,
  ) {}

  public clearUser(): void {
    this.currentUserSubject.next(null);
  }

  /**
   * @description This method is used to get the user by id and set it in the currentUserSubject.
   * @param id
   * @returns
   */
  public async setUser(id: string): Promise<boolean> {
    try {
      const query: IQueryFilter = new IQueryFilter(_.cloneDeep(API_ASSOCIATIONS.user));
      query.filter = { id: id };

      const data = await lastValueFrom(
        apiCallWrapper(this.userApi.list(query), {
          notificationsService: this.notifications,
          action: "Obtaining user details",
        }),
      );

      if (data && data.rows && data.rows.length > 0) {
        let user: User = plainToClass(User, data.rows[0]);

        //TODO: tried to put this in a SentryService but it didn't work
        this.currentUserSubject.next(user);
        Sentry.setUser({
          id: user.id,
          email: user.email,
        });
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  public get currentUser(): User | null {
    return this.currentUserSubject.value;
  }

  public get defaultLandingPage(): string {
    if (!this.currentUser || !this.currentUser.roles.length) {
      return "/login";
    }

    if (!this.currentUser.isClient) {
      if (this.currentUser.isAdmin) {
        return "/admin-dashboard";
      } else if (this.currentUser.isDRO) {
        return "/dro-dashboard";
      } else {
        return "/dashboard";
      }
    } else {
      return "/client-dashboard";
    }
  }
}
