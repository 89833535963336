import { Injectable } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { apiCallWrapper } from "../api/api.util";
import { FormResponseApi } from "../api/form-response.api";
import { FormResponseEdit } from "../model/forms/form-response.model";
import { IMergeItem } from "../model/forms/merge-result.model";

@Injectable({
  providedIn: "root",
})
export class FormResponseService {
  constructor(
    private notifications: NotificationsService,
    private formResponseApi: FormResponseApi,
  ) {}

  public create = (data: Omit<FormResponseEdit, "id">) => {
    return apiCallWrapper(this.formResponseApi.create(data), {
      notificationsService: this.notifications,
      action: "Create response",
      failTitle: "Failed to submit response",
      successTitle: "Response successfully submitted",
    });
  };

  public update = (data: FormResponseEdit) => {
    return apiCallWrapper(this.formResponseApi.update(data.id, data), {
      notificationsService: this.notifications,
      action: "Edit response",
      failTitle: "Failed to edit response",
      successTitle: "Successfully edited response",
    });
  };

  public delete = (responseId: number | string) => {
    return apiCallWrapper(this.formResponseApi.delete(responseId), {
      notificationsService: this.notifications,
      action: "Delete response",
      failTitle: "Failed to delete response",
      successTitle: "Successfully deleted response",
    });
  };

  public readScansLocations = (responseId: number | string) => {
    return apiCallWrapper(this.formResponseApi.readScansLocations(responseId), {
      notificationsService: this.notifications,
      action: "Fetch scans urls",
      failTitle: "Failed to fetch scans urls",
      successTitle: "Successfully fetched scans urls",
    });
  };

  public merge = (data: { mergeItems: IMergeItem[]; isPreview: boolean }) => {
    return apiCallWrapper(this.formResponseApi.merge(data), {
      notificationsService: this.notifications,
      action: data.isPreview ? "Preview merge responses" : "Merge responses",
      failTitle: `Failed to ${data.isPreview ? "preview " : ""}merge responses`,
      successTitle: `Successfully ${data.isPreview ? "preview " : ""}merged responses`,
    });
  };
}
