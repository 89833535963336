import { Type } from "class-transformer";
import { CareLevel } from "./care-level.model";
import { ContactDeal } from "./contact-deal.model";
import { IdentifiableContact } from "./contracts/contact.contract";
import { PractitionerLevel } from "./practitioner-level.model";
import { PractitionerType } from "./practitioner-type.model";
import { SpecialityLevel } from "./speciality-level.model";

export class Contact extends IdentifiableContact {
  phone: string;
  pid: string;
  clientType: string; //TODO: enum
  postalStreet: string;
  postalAddress: string;
  postalCity: string;
  postalState: string;
  postalPostCode: string;
  country: string;
  @Type(() => ContactDeal)
  ContactDeal: ContactDeal; //TODO: maybe revisit many to many relationships in the API and see if we can make this camelCase
  @Type(() => CareLevel)
  careLevel: CareLevel;
  @Type(() => PractitionerLevel)
  practitionerLevel: PractitionerLevel;
  @Type(() => SpecialityLevel)
  specialityLevel: SpecialityLevel;
  @Type(() => PractitionerType)
  practitionerType: PractitionerType;
  isArchived: boolean;
  @Type(() => Boolean)
  isFromCRM: boolean;

  public get isPrimary(): boolean {
    return this.ContactDeal && this.ContactDeal.isPrimaryContact;
  }

  public get postalAddressfull(): string {
    return `${!!this.postalStreet ? this.postalStreet + ", " : ""}${
      !!this.postalAddress ? this.postalAddress + ", " : ""
    }${!!this.postalCity ? this.postalCity + ", " : ""}${
      !!this.postalState ? this.postalState + ", " : ""
    }${!!this.postalPostCode ? this.postalPostCode + ", " : ""}${
      !!this.country ? this.country : ""
    }`.replace(/,\s*$/, "");
  }

  get practitionerTypeDescription(): string {
    const careLevel = this.careLevel?.label;
    const practitionerLevel = this.practitionerLevel?.label;
    const specialty = this.specialityLevel?.label;

    const labels: string[] = [];
    if (careLevel) labels.push(careLevel);
    if (practitionerLevel) labels.push(practitionerLevel);
    if (specialty) labels.push(specialty);

    return labels.join(" - ");
  }
}
