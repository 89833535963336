import { BaseModel } from "../base.model";
import { FormTemplateCategoryEnum } from "./contract/form-template-category-contract";

export class FormTemplateCatgory extends BaseModel {
  id: number;
  name: string;
  description: string;

  get isPAIS() {
    return this.id === FormTemplateCategoryEnum.PAIS;
  }
}
