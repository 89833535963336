import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as AOS from "aos";
import "reflect-metadata";
import { AppModule } from "./app/main/app.module";
import { logger } from "./app/main/util/Logger";
import { environment } from "./environments/environment";

logger.info(`Starting Application. BuildId[${environment.buildId}]`);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((ref) => {
    // Ensure Angular destroys itself on hot reloads.
    if (window["ngRef"]) {
      window["ngRef"].destroy();
    }
    window["ngRef"] = ref;

    // Otherwise, log the boot error
  })
  .catch((err) => console.error(err));

AOS.init();
