import { AfterViewInit, Component, Input, OnInit, ViewChildren } from "@angular/core";
import {
  QuestionEnumValue,
  QuestionList,
  QuestionListFormControlOption,
} from "src/app/main/model/forms/question-list.model";
import { FormCompletionBaseComponent } from "../form-completion-base.component";

@Component({
  selector: "app-question-list",
  templateUrl: "./question-list.component.html",
  styleUrls: ["./question-list.component.scss"],
})
export class QuestionListComponent
  extends FormCompletionBaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChildren("input") inputRefs;
  @Input() public question: QuestionList;

  public currentValue: number[] = [];
  public currentText = "";
  public roundUp = (n: number) => Math.ceil(n);

  ngOnInit() {
    if (this.control.value && this.control.value.length) {
      this.currentValue = this.control.value.map((c) => c.value);
      const valueWithText = this.control.value.find((c) => c.text);
      if (valueWithText) {
        this.currentText = valueWithText.text;
      }
    }
  }

  ngAfterViewInit() {
    for (let i = 0; i < this.inputRefs._results.length; i++) {
      this.inputRefs._results[i].nativeElement.name =
        this.question.getName() + (this.question.questionEnum.isMultiple ? i.toString() : "");
    }
  }

  public selectOption(enumValue: QuestionEnumValue): void {
    if (this.question.questionEnum.isMultiple) {
      this.selectOptionMultiple(enumValue);
    } else {
      this.selectOptionSingle(enumValue);
    }
  }

  public selectOptionSingle(enumValue: QuestionEnumValue): void {
    this.currentValue = [enumValue.value];

    if (this.service) {
      let newValue: QuestionListFormControlOption = { value: enumValue.value };
      if (enumValue.isText) {
        newValue.text = this.currentText;
      }

      this.control.setValue([newValue]);
    }
  }

  public selectOptionMultiple(enumValue: QuestionEnumValue): void {
    const findIndex = this.currentValue.findIndex((val) => val === enumValue.value);
    if (findIndex > -1) {
      this.currentValue.splice(findIndex, 1);
    } else {
      this.currentValue.push(enumValue.value);
    }

    if (this.service) {
      if (findIndex > -1) {
        this.control.value.splice(findIndex, 1);
      } else {
        let newValue: QuestionListFormControlOption = { value: enumValue.value, isMultiple: true };
        if (enumValue.isText) {
          newValue.text = this.currentText;
        }
        this.control.setValue([...(this.control.value || []), newValue]);
      }
    }
  }

  public onTextChange(event: any, enumValue: QuestionEnumValue): void {
    if (this.question.questionEnum.isMultiple) {
      this.onTextChangeMultiple(event, enumValue);
    } else {
      this.onTextChangeSingle(event, enumValue);
    }
  }

  public onTextChangeSingle(event: any, enumValue: QuestionEnumValue): void {
    this.currentValue = [enumValue.value];
    this.currentText = event.target.value;

    if (this.service) {
      this.control.setValue([{ value: enumValue.value, text: event.target.value }]);
    }
  }

  public onTextChangeMultiple(event: any, enumValue: QuestionEnumValue): void {
    const findIndex = this.currentValue.findIndex((val) => val === enumValue.value);
    if (findIndex === -1) {
      this.currentValue.push(enumValue.value);
    }
    this.currentText = event.target.value;

    if (this.service) {
      const newValue: QuestionListFormControlOption = {
        value: enumValue.value,
        text: this.currentText,
        isMultiple: true,
      };
      if (findIndex > -1) {
        this.control.value.splice(findIndex, 1, newValue);
      } else {
        this.control.setValue([...(this.control.value || []), newValue]);
      }
    }
  }

  public get control() {
    return this.service.formGroup.controls[this.question.getName()];
  }
}
