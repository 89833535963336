import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { QuestionRangeGroupWeightingValue } from "./question-range-group-weighting-value.model";

export class QuestionRangeGroupWeighting extends BaseModel {
  id: number;
  name: string;
  description: string;
  @Type(() => QuestionRangeGroupWeightingValue)
  qRangeGroupWValues: QuestionRangeGroupWeightingValue[];

  getQuestionWeightingByQuestionRangeValue(rangeValue: number): number {
    return this.qRangeGroupWValues.find((q) => q.qRangeValue === rangeValue)?.weightedValue || 0;
  }
}
