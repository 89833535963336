import { DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BooleanToYesNoPipe } from "../../pipe/boolean-to-yes-no.pipe";
import { SafeHtmlPipe } from "../../pipe/safe-html.pipe";
import { TimeAgoPipe } from "../../pipe/time-ago.pipe";
import { TimeConverterPipe } from "../../pipe/time-convertor.pipe";
import { GenerateReportComponent } from "../dashboard/generate-report/generate-report.component";
import { ViewSurveyComponent } from "../dashboard/view-survey/view-survey.component";
import { AddPaperBundleFormComponent } from "./add-paper-bundle-form/add-paper-bundle-form.component";
import { AdvancedSearchComponent } from "./advanced-search/advanced-search.component";
import { AnswerStatusComponent } from "./answer-status/answer-status.component";
import { BenchmarkCompareComponent } from "./benchmark-components/benchmark-compare/benchmark-compare.component";
import { BenchmarkFilterViewComponent } from "./benchmark-components/benchmark-filter/benchmark-filter-view.component";
import { BenchmarkStatusComponent } from "./benchmark-components/benchmark-status/benchmark-status.component";
import { ColleagueEditFormComponent } from "./colleague-edit-form/colleague-edit-form.component";
import { ColleagueListComponent } from "./colleague-list/colleague-list.component";
import { ColleagueResponseStatsComponent } from "./colleague-response-stats/colleague-response-stats.component";
import { CompanyComponent } from "./company/company.component";
import { ContactComponent } from "./contact/contact.component";
import { DealComponent } from "./deal/deal.component";
import { FormCompletionBaseComponent } from "./form-completion/form-completion-base.component";
import { FormCompletionTemplateQuestionComponent } from "./form-completion/form-completion-template-question/form-completion-template-question.component";
import { FormCompletionTemplateSectionComponent } from "./form-completion/form-completion-template-section/form-completion-template-section.component";
import { FormCompletionTemplateComponent } from "./form-completion/form-completion-template/form-completion-template.component";
import { QuestionListComponent } from "./form-completion/question-list/question-list.component";
import { QuestionRangeComponent } from "./form-completion/question-range/question-range.component";
import { QuestionTextComponent } from "./form-completion/question-text/question-text.component";
import { FormTemplateBaseComponent } from "./form-template-base/form-template-base.component";
import { FormTemplateQuestionBaseComponent } from "./form-template-base/form-template-question-base.component";
import { FormTemplateSectionBaseComponent } from "./form-template-base/form-template-section-base.component";
import { MergeResponsesModalComponent } from "./merge-responses-modal/merge-responses-modal.component";
import { ConfirmDialogComponent } from "./modals/confirm-dialog/confirm-dialog.component";
import { DialogComponent } from "./modals/dialog/dialog.component";
import { NavComponent } from "./nav/nav.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { PrintDocumentsComponent } from "./print-documents/print-documents.component";
import { PublicFooterComponent } from "./public-footer/public-footer.component";
import { PublicHeaderComponent } from "./public-header/public-header.component";
import { ResolveAlertComponent } from "./resolve-alert/resolve-alert.component";
import { ResponseFormQuestionListComponent } from "./response-verification/response-form-question-list/response-form-question-list.component";
import { ResponseFormTemplateQuestionComponent } from "./response-verification/response-form-template-question/response-form-template-question.component";
import { ResponseFormTemplateSectionComponent } from "./response-verification/response-form-template-section/response-form-template-section.component";
import { ResponseFormTemplateComponent } from "./response-verification/response-form-template/response-form-template.component";
import { ScanViewerComponent } from "./scan-viewer/scan-viewer.component";
import { SchedulerRefreshComponent } from "./scheduler-refresh/scheduler-refresh.component";
import { SecureButtonComponent } from "./secure-button/secure-button.component";
import { SecureFooterComponent } from "./secure-footer/secure-footer.component";
import { SecureHeaderClientComponent } from "./secure-header-client/secure-header-client.component";
import { SecureHeaderComponent } from "./secure-header/secure-header.component";
import { SecurePageClientComponent } from "./secure-page-client/secure-page-client.component";
import { SecurePageWithNavComponent } from "./secure-page-with-nav/secure-page-with-nav.component";
import { SurveyInstanceComponent } from "./survey-instance/survey-instance.component";
import { SurveyStatusComponent } from "./survey-status/survey-status.component";
import { TableComponent } from "./table/table.component";
import { ViewPaperBundleHistoryComponent } from "./view-paper-bundle-history/view-paper-bundle-history.component";

const MATERIAL_MODULES = [
  MatAutocompleteModule,
  MatFormFieldModule,
  MatSelectModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatInputModule,
  MatTooltipModule,
];

@NgModule({
  declarations: [
    AddPaperBundleFormComponent,
    AdvancedSearchComponent,
    BenchmarkCompareComponent,
    BenchmarkFilterViewComponent,
    BenchmarkStatusComponent,
    BooleanToYesNoPipe,
    SafeHtmlPipe,
    TimeAgoPipe,
    TimeConverterPipe,
    AnswerStatusComponent,
    ColleagueEditFormComponent,
    ColleagueListComponent,
    ColleagueResponseStatsComponent,
    ContactComponent,
    CompanyComponent,
    DealComponent,
    GenerateReportComponent,
    NavComponent,
    PaginationComponent,
    PrintDocumentsComponent,
    PublicFooterComponent,
    PublicHeaderComponent,
    SchedulerRefreshComponent,
    FormCompletionTemplateComponent,
    FormCompletionTemplateSectionComponent,
    FormCompletionTemplateQuestionComponent,
    QuestionTextComponent,
    QuestionRangeComponent,
    QuestionListComponent,
    FormCompletionBaseComponent,
    ResolveAlertComponent,
    SecureButtonComponent,
    SecureFooterComponent,
    SecureHeaderComponent,
    SecureHeaderClientComponent,
    SecurePageClientComponent,
    SecurePageWithNavComponent,
    SurveyInstanceComponent,
    SurveyStatusComponent,
    TableComponent,
    ConfirmDialogComponent,
    DialogComponent,
    ViewPaperBundleHistoryComponent,
    ScanViewerComponent,
    ViewSurveyComponent,
    FormTemplateBaseComponent,
    FormTemplateQuestionBaseComponent,
    FormTemplateSectionBaseComponent,
    ResponseFormTemplateComponent,
    ResponseFormTemplateQuestionComponent,
    ResponseFormTemplateSectionComponent,
    ResponseFormQuestionListComponent,
    MergeResponsesModalComponent,
  ],
  imports: [
    ...MATERIAL_MODULES,
    BrowserModule,
    FormsModule,
    FontAwesomeModule,
    RouterModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  exports: [
    ...MATERIAL_MODULES,
    AddPaperBundleFormComponent,
    AdvancedSearchComponent,
    AnswerStatusComponent,
    BenchmarkCompareComponent,
    BenchmarkFilterViewComponent,
    BenchmarkStatusComponent,
    ColleagueEditFormComponent,
    ColleagueListComponent,
    ColleagueResponseStatsComponent,
    CompanyComponent,
    ContactComponent,
    DealComponent,
    GenerateReportComponent,
    NavComponent,
    PaginationComponent,
    PrintDocumentsComponent,
    PublicFooterComponent,
    PublicHeaderComponent,
    ResolveAlertComponent,
    SecureButtonComponent,
    SecureFooterComponent,
    SecureHeaderComponent,
    SecureHeaderClientComponent,
    SecurePageClientComponent,
    SecurePageWithNavComponent,
    SchedulerRefreshComponent,
    FormCompletionTemplateComponent,
    ResponseFormTemplateComponent,
    SurveyInstanceComponent,
    SurveyStatusComponent,
    TableComponent,
    ConfirmDialogComponent,
    DialogComponent,
    ViewPaperBundleHistoryComponent,
    ScanViewerComponent,
    ViewSurveyComponent,
    TimeAgoPipe,
    TimeConverterPipe,
  ],
  providers: [DatePipe],
})
export class SharedModule {}
