import { Type } from "class-transformer";
import { Contact } from "../crm/contact.model";

/**
 * Type on contact on form instance
 */
export enum FormInstanceContactType {
  Recipient = "recipient",
  Subject = "subject",
  SMC = "smc",
}

export class FormInstanceContact extends Contact {
  uuid: string;
  type: FormInstanceContactType;
  @Type(() => Boolean)
  optedOut: boolean;
  @Type(() => Boolean)
  stopAutoEmails: boolean;
  @Type(() => Boolean)
  emailSent: boolean;

  get isSMC(): boolean {
    return this.type === FormInstanceContactType.SMC;
  }

  get isSubject(): boolean {
    return this.type === FormInstanceContactType.Subject;
  }

  get isRecipient(): boolean {
    return this.type === FormInstanceContactType.Recipient;
  }
}
