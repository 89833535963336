import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { Document } from "./document.model";

export class FormGroupInstanceDocument extends BaseModel {
  id: number;
  mergedLocation: string;
  convertedLocation: string;
  formGroupInstanceId: number;
  formInstanceId: number;
  contactExternalId: string;
  @Type(() => Document)
  document: Document;
}
