import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { QuestionRangeGroup } from "./question-range-group.model";

export class Question extends BaseModel {
  id: number;
  name: string;
  description: string;
  qTypeId: number;
  defaultValue: string | number | null;
  @Type(() => Boolean)
  isQARequired: boolean;
  @Type(() => Boolean)
  isArchived: boolean;
  @Type(() => QuestionRangeGroup)
  questionRangeGroup: QuestionRangeGroup;
}
