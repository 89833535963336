import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Transform, Type, plainToInstance } from "class-transformer";
import { isArray } from "lodash-es";
import { FormAnswer, FormAnswerObject } from "./form-answer.model";
import { FormTemplateQuestion } from "./form-template-question.model";

export type QuestionListFormControlOption = {
  value: string | number;
  text?: string;
  isMultiple?: boolean;
};
type QuestionListFormControlValue = QuestionListFormControlOption | QuestionListFormControlOption[];

export class QuestionList extends FormTemplateQuestion {
  @Transform(({ obj }) => ({
    name: obj.questionEnum.name,
    description: obj.questionEnum.description,
    enumValues: obj.questionEnum.enumValues.map((q) => plainToInstance(QuestionEnumValue, q)),
    isMultiple: !!obj.questionEnum.isMultiple,
  }))
  questionEnum: {
    name: string;
    description: string;
    enumValues: QuestionEnumValue[];
    isMultiple: boolean;
  };

  public collectAnswer(formGroup: FormGroup, isEdit: boolean): FormAnswer[] | null {
    const questionAnswers = formGroup.value[this.getName()];

    if (["", null].includes(questionAnswers)) {
      return null;
    }

    const formAnswers: FormAnswer[] = [];
    for (let i = 0; i < questionAnswers.length; i++) {
      const formAnswer = new FormAnswer();
      formAnswer.formTemplateQuestionId = this.id;
      formAnswer.isMultiple = questionAnswers[i].isMultiple;
      formAnswer[this.getAnswerDataField(isEdit)[0]] = Number(questionAnswers[i].value);
      formAnswer[this.getAnswerDataField(isEdit)[1]] = !!questionAnswers[i].text
        ? encodeURIComponent(questionAnswers[i].text)
        : undefined;
      formAnswers.push(formAnswer);
    }

    return formAnswers;
  }

  // Override parent method
  public collectFormControl(answersToDefaultTo: FormAnswerObject): Record<string, FormControl> {
    let defaultValue: QuestionListFormControlValue =
      this.defaultValue !== null ? { value: this.defaultValue, text: "" } : this.defaultValue;

    if (answersToDefaultTo[this.id] !== undefined) {
      if (isArray(answersToDefaultTo[this.id])) {
        // use edited fields if at least one of the edited field is not null
        const hasEditedAnswer = !!(answersToDefaultTo[this.id] as FormAnswer[]).find(
          (a) => a[this.getAnswerDataField(true)[0]] !== null,
        );
        defaultValue = (answersToDefaultTo[this.id] as FormAnswer[]).map((a) => ({
          value: a[this.getAnswerDataField(hasEditedAnswer)[0]],
          text: a[this.getAnswerDataField(hasEditedAnswer)[1]] || "",
          isMultiple: true,
        }));
      } else {
        const hasEditedAnswer =
          answersToDefaultTo[this.id][this.getAnswerDataField(true)[0]] !== null;
        defaultValue = {
          value: answersToDefaultTo[this.id][this.getAnswerDataField(hasEditedAnswer)[0]],
          text: answersToDefaultTo[this.id][this.getAnswerDataField(hasEditedAnswer)[1]] || "",
        };
      }
    }
    return {
      [this.getName()]: new FormControl<QuestionListFormControlValue>(
        defaultValue,
        this.required ? Validators.required : Validators.nullValidator,
      ),
    };
  }

  public readDataFromAnswers(
    answers: FormAnswerObject,
    isEdit = false,
    field: "label" | "value" = "label",
  ): string | number {
    if (answers[this.id]) {
      if (isArray(answers[this.id])) {
        const texts: { text: string; order: number }[] = [];
        const multiAnswers = answers[this.id] as FormAnswer[];

        for (let i = 0; i < multiAnswers.length; i++) {
          const answer = multiAnswers[i];
          const enumValue = this.questionEnum.enumValues.find(
            (enumValue) => enumValue.value === answer[this.getAnswerDataField(isEdit)[0]],
          );
          if (enumValue) {
            texts.push({
              text: enumValue.isText
                ? `${enumValue[field]}: ${answer[this.getAnswerDataField(isEdit)[1]]}`
                : enumValue[field].toString(),
              order: enumValue.order,
            });
          }
        }
        return texts
          .sort((a, b) => a.order - b.order)
          .map((a) => a.text)
          .join(", ");
      } else {
        const answer = answers[this.id];
        const enumValue = this.questionEnum.enumValues.find(
          (enumValue) => enumValue.value === answer[this.getAnswerDataField(isEdit)[0]],
        );
        if (enumValue) {
          return enumValue.isText
            ? `${enumValue[field]}: ${answer[this.getAnswerDataField(isEdit)[1]]}`
            : enumValue[field];
        }
      }
    }
    return "";
  }
}

export class QuestionEnumValue {
  value: number;
  order: number;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  label: string;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  description: string;
  @Type(() => Boolean)
  isText: boolean;
}
