import { Component, Input } from "@angular/core";
import { FormTemplateQuestion } from "src/app/main/model/forms/form-template-question.model";
import { QuestionList } from "src/app/main/model/forms/question-list.model";
import { QuestionRange } from "src/app/main/model/forms/question-range.model";
import { QuestionText } from "src/app/main/model/forms/question-text.model";

@Component({ template: "" })
export class FormTemplateQuestionBaseComponent {
  @Input() public formTemplateQuestion: FormTemplateQuestion;

  public isQuestionRange(question: FormTemplateQuestion): question is QuestionRange {
    return FormTemplateQuestion.isQuestionRange(question);
  }
  public isQuestionList(question: FormTemplateQuestion): question is QuestionList {
    return FormTemplateQuestion.isQuestionList(question);
  }
  public isQuestionText(question: FormTemplateQuestion): question is QuestionText {
    return FormTemplateQuestion.isQuestionText(question);
  }
}
