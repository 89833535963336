import { AbstractControl } from "@angular/forms";
import { EmailRegex, GenericEmailRegex, PasswordReg } from "src/common/constants/regex.constants";

export function emailValidator(control: AbstractControl): { [key: string]: any } | null {
  if (isEmptyInputValue(control.value)) {
    // if nothing has been passed then let other validator catch it
    return null;
  }
  const isValidEmail = EmailRegex.test(control.value.trim());
  return isValidEmail ? null : { invalidEmail: true };
}

export function genericEmailValidator(control: AbstractControl): { [key: string]: any } | null {
  if (isEmptyInputValue(control.value)) {
    // if nothing has been passed then let other validator catch it
    return null;
  }
  const isValidEmail = !GenericEmailRegex.test(control.value.trim());
  return isValidEmail ? null : { invalidEmail: true };
}

export function passwordValidator(control: AbstractControl): { [key: string]: any } | null {
  if (isEmptyInputValue(control.value)) {
    // if nothing has been passed then let other validator catch it
    return null;
  }
  const isValidPassword = PasswordReg.test(control.value.trim());
  return isValidPassword ? null : { invalidPassword: true };
}

export function trimRequiredValidator(control: AbstractControl): { [key: string]: any } | null {
  const isValid = !!(
    control.value &&
    typeof control.value === "string" &&
    control.value.trim().length > 0
  );
  return isValid ? null : { required: { value: control.value } };
}

function isEmptyInputValue(value: any): boolean {
  return (
    value == null || value.length === 0 || (typeof value === "string" && value.trim().length === 0)
  );
}

export function validateConfirmPassword(control: AbstractControl): boolean {
  const passwordControl = control.root.get("password");
  if (!passwordControl) {
    return false;
  }
  if (passwordControl.value !== control.value) {
    return false;
  }
  return true;
}
