import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FormInstance } from "../model/forms/form-instance.model";
import { IQueryFilter, QueryResult } from "../model/query.filter.class";
import { createUrl, getPublicRoutesHeaders, httpParams, queryToParams } from "./api.util";
import { GenericApi, HasIdOrExternalValueOrExternalIdOrServiceName } from "./generic.api";

// Convenience Type
type T = FormInstance;
type I = FormInstance;

@Injectable({
  providedIn: "root",
})
export class FormInstanceApi extends GenericApi<T> {
  public path = "form-instance";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  /**
   * @description Get the formInstance information by making a GET request with the formInstanceUUID.
   * @param {string} uuid - The formInstanceUUID to fetch
   * @returns {Promise<FormInstance>} - A promise that resolves to the response object.
   * @readonly
   * @example
   * ```
   * formInstanceApi.getByUUID(<uuid>).subscribe({
   *   next: response => console.log(response), // The formInstance response object
   *   error: error => console.error(error) // Handle error
   * });
   * ```
   */
  getByUUID = (
    uuid: string,
    isOnline = true,
  ): Observable<T & HasIdOrExternalValueOrExternalIdOrServiceName<I>> => {
    let params = httpParams();
    params = params.append("isOnline", isOnline ? 1 : 0);

    return this.httpClient.get<T & HasIdOrExternalValueOrExternalIdOrServiceName<I>>(
      createUrl(this.path, "get-form-instance", uuid),
      {
        headers: getPublicRoutesHeaders(),
        params: params,
      },
    );
  };

  list = (
    query: IQueryFilter,
  ): Observable<QueryResult<T & HasIdOrExternalValueOrExternalIdOrServiceName<I>>> => {
    return this.httpClient.get<QueryResult<T & HasIdOrExternalValueOrExternalIdOrServiceName<I>>>(
      createUrl(this.path),
      {
        params: queryToParams(query),
      },
    );
  };
}
