import { ViewportScroller } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormTemplateSection } from "src/app/main/model/forms/form-template-section.model";
import { FormTemplate } from "src/app/main/model/forms/form-template.model";
import { FormCompletionService } from "src/app/main/services/form-completion/form-completion.service";
import { FormTemplateBaseComponent } from "../../form-template-base/form-template-base.component";

@Component({
  selector: "app-form-completion-template",
  templateUrl: "./form-completion-template.component.html",
  styleUrls: ["./form-completion-template.component.scss"],
})
export class FormCompletionTemplateComponent extends FormTemplateBaseComponent {
  @Input() public formTemplate: FormTemplate;

  public slides: FormTemplateSection[][];
  public currentSlideIndex = 0;

  constructor(
    protected viewPortScroller: ViewportScroller,
    private formCompletionService: FormCompletionService,
  ) {
    super(viewPortScroller);
  }

  public validateSection() {
    // Check if current section meets formGroup validation before going to next slide
    const questions = this.slides[this.currentSlideIndex].reduce(
      (acc, curr) => [...acc, ...curr.questions],
      [],
    );
    const hasInvalidQuestion = questions.some(
      (question) => this.service.formGroup.controls[question.getName()].invalid,
    );
    if (hasInvalidQuestion) {
      this.service.showErrors = true;
      return;
    } else {
      this.service.showErrors = false;
      this.goNextSlide();
    }
  }

  public get service() {
    return this.formCompletionService;
  }
}
