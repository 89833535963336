import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { User } from "../../model/auth/user.model";
import { HasId } from "../../model/generics";
import { IQueryFilter, QueryResult } from "../../model/query.filter.class";
import { createUrl, queryToParams } from "../api.util";
import { GenericApi } from "../generic.api";

// Convenience Type
type T = User;
type I = User;

@Injectable({
  providedIn: "root",
})
export class UserApi extends GenericApi<T> {
  public path = "user";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  get = (id: number | string): Observable<T & HasId<T>> => {
    return this.httpClient.get<T & HasId<T>>(createUrl(this.path, id));
  };

  list = (query: IQueryFilter): Observable<QueryResult<T & HasId<I>>> => {
    return this.httpClient.get<QueryResult<T & HasId<I>>>(createUrl(this.path), {
      params: queryToParams(query),
    });
  };
}
