import { Transform, Type } from "class-transformer";
import { QuestionTypeNumberEnum } from "./contract/form-instance-contract";
import { FormResponse } from "./form-response.model";
import { FormTemplateQuestion } from "./form-template-question.model";
import { Reviewable } from "./reviewable.model";

export const answerType = ["Paper", "Online"];

/**
 * @description temp solution to handle string data that isn't encoded
 * @param value
 * @returns
 */
function _transform(value): string {
  //TODO: don't use this method, make sure we encode all responses
  try {
    return decodeURIComponent(value || "");
  } catch (error) {
    return value || "";
  }
}

export class FormAnswer extends Reviewable {
  id?: number;
  formTemplateQuestionId: number;
  formResponseId?: number;
  @Transform(({ value }) => _transform(value))
  stringData?: string;
  enumData?: number;
  rangeData?: number;
  @Transform(({ value }) => _transform(value || ""))
  editedStringData?: string;
  editedEnumData?: number;
  editedRangeData?: number;
  @Type(() => Boolean)
  isMultiple?: boolean;
  @Type(() => FormTemplateQuestion)
  formTemplateQuestion: FormTemplateQuestion;
  @Type(() => FormResponse)
  formResponse: FormResponse;

  get answerWeighting(): number {
    let returnValue: number | undefined = undefined;

    if (
      this.rangeData &&
      this.formTemplateQuestion &&
      this.formTemplateQuestion.questionVariant &&
      this.formTemplateQuestion.questionVariant.question &&
      this.formTemplateQuestion.questionVariant.question.qTypeId === QuestionTypeNumberEnum.Range
    ) {
      if (
        this.formTemplateQuestion.questionVariant.question.questionRangeGroup &&
        this.formTemplateQuestion.questionVariant.question.questionRangeGroup.qRangeGroupWs &&
        this.formTemplateQuestion.questionVariant.question.questionRangeGroup.qRangeGroupWs.length
      ) {
        returnValue =
          this.formTemplateQuestion.questionVariant.question.questionRangeGroup.qRangeGroupWs[0].getQuestionWeightingByQuestionRangeValue(
            //all the weightings are the same for the same range group
            this.rangeData,
          );
      }
    }

    return returnValue || 0;
  }

  /**
   * Returns the question variant value, e.g. "Your%20level%20of%20satisfaction%20with%20making%20an%20appointment"
   */
  get questionVariant(): string {
    let returnValue = "";

    if (this.formTemplateQuestion && this.formTemplateQuestion.questionVariant) {
      returnValue = this.formTemplateQuestion.questionVariant.value;
    }

    return returnValue;
  }

  /**
   * Returns the question range description, e.g. "Poor, Fair, Good, Very Good, Excellent, Unable to comment"
   */
  get questionRangeGroupDescription(): string {
    let returnValue = "";

    if (
      this.rangeData &&
      this.formTemplateQuestion &&
      this.formTemplateQuestion.questionVariant &&
      this.formTemplateQuestion.questionVariant.question &&
      this.formTemplateQuestion.questionVariant.question.qTypeId === QuestionTypeNumberEnum.Range
    ) {
      returnValue =
        this.formTemplateQuestion.questionVariant.question.questionRangeGroup.description;
    }

    return returnValue;
  }
}

export type FormAnswerObject = Record<number, FormAnswer | FormAnswer[]>;
