import { FormControl } from "@angular/forms";
import { Transform, Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { FormAnswer, FormAnswerObject } from "./form-answer.model";
import { FormTemplateCatgory } from "./form-template-category.model";
import { FormTemplateQuestion, QuestionTypeEnum } from "./form-template-question.model";
import { FormTemplateSection } from "./form-template-section.model";
import { QuestionText } from "./question-text.model";

export class FormTemplate extends BaseModel {
  id: number;
  uuid: string;
  name: string;
  internalName: string;
  description: string;
  minResponsesForAbrv: number;
  minResponsesForFull: number;
  formTemplateCategoryId: number;
  @Type(() => Boolean)
  hasSMC: boolean;
  @Type(() => Boolean)
  isCFET: boolean;
  @Type(() => Boolean)
  isAvailableOffline: boolean;
  @Type(() => Boolean)
  allowCobranding: boolean;
  @Type(() => Boolean)
  isDraft: boolean;
  @Type(() => Boolean)
  isArchived: boolean;
  timerInMinutes: number | null;
  alertBeforeEndTimerInMinutes: number | null;
  image: string | null;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  onlineThankyouMessage: string | null;
  @Type(() => FormTemplateSection)
  formTemplateSections: FormTemplateSection[];
  @Type(() => FormTemplateCatgory)
  formTemplateCategory: FormTemplateCatgory;

  public collectFormControls(
    answersToDefaultTo: FormAnswerObject = {},
  ): Record<string, FormControl> {
    return this.questions.reduce(
      (acc, curr) => ({
        ...acc,
        ...curr.collectFormControl(answersToDefaultTo),
      }),
      {},
    );
  }

  public collectCommentsFormControls(
    answersToDefaultTo: FormAnswerObject = {},
  ): Record<string, FormControl> {
    return this.collectAnsweredComments(answersToDefaultTo).reduce(
      (acc, curr) => ({
        ...acc,
        ...curr.collectFormControl(answersToDefaultTo),
      }),
      {},
    );
  }

  public get questions(): FormTemplateQuestion[] {
    return this.formTemplateSections.reduce(
      (sectionsAcc, sectionCurr) => [...sectionsAcc, ...sectionCurr.questions],
      [],
    );
  }

  public collectAnsweredComments(answers: FormAnswerObject = {}): QuestionText[] {
    const answeredComments = this.questions.filter((question) => {
      if (
        question.type === QuestionTypeEnum.Text &&
        answers[question.id] !== undefined &&
        !Array.isArray(answers[question.id])
      ) {
        const textAnswer = answers[question.id] as FormAnswer;
        return !!textAnswer.editedStringData || !!textAnswer.stringData;
      }
      return false;
    }) as QuestionText[];
    return answeredComments;
  }
}
