import { FormGroup } from "@angular/forms";
import { Transform, plainToInstance } from "class-transformer";
import { FormAnswerObject, FormAnswer } from "./form-answer.model";
import { FormTemplateQuestion } from "./form-template-question.model";

export class QuestionRange extends FormTemplateQuestion {
  @Transform(({ obj }) => ({
    name: obj.questionRangeGroup.name,
    description: obj.questionRangeGroup.description,
    ranges: obj.questionRangeGroup.ranges.map((q) => plainToInstance(QuestionRangeValue, q)),
  }))
  questionRangeGroup: {
    name: string;
    description: string;
    ranges: QuestionRangeValue[];
  };

  public collectAnswer(formGroup: FormGroup, isEdit: boolean): FormAnswer[] | null {
    if (["", null].includes(formGroup.value[this.getName()])) {
      return null;
    }

    const formAnswer = new FormAnswer();
    formAnswer.formTemplateQuestionId = this.id;
    formAnswer[this.getAnswerDataField(isEdit)[0]] = Number(formGroup.value[this.getName()]);

    return [formAnswer];
  }

  public readDataFromAnswers(
    answers: FormAnswerObject,
    isEdit = false,
    field: "label" | "value" = "label",
  ): string | number {
    if (answers[this.id]) {
      const answer = answers[this.id];
      const rangeValue = this.questionRangeGroup.ranges.find(
        (range) => range.value === answer[this.getAnswerDataField(isEdit)[0]],
      );
      return rangeValue ? rangeValue[field] : "";
    }
    return "";
  }
}

export class QuestionRangeValue {
  value: number;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  label: string;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  description: string;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  icon: string;
}
