<div
  *ngIf="service.isLoadingFormInstance || !service.formInstance"
  class="vh-100 d-flex justify-content-center align-items-center"
>
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div *ngIf="!service.isLoadingFormInstance && service.formInstance">
  <app-public-header
    [cobrandedLogo]="service.formInstance.formGroupInstance.formGroupCompany?.signedLogoUrl"
  />

  <ng-container *ngIf="service.currentState === states.Intro">
    <ng-container *ngTemplateOutlet="intro" />
  </ng-container>

  <ng-container *ngIf="service.currentState === states.Progress">
    <ng-container *ngTemplateOutlet="form" />
  </ng-container>

  <ng-container *ngIf="service.currentState === states.ThankYou">
    <ng-container *ngTemplateOutlet="thankyou" />
  </ng-container>

  <div
    *ngIf="service.isSubmitting"
    class="overlay d-flex align-items-center justify-content-center"
  >
    <div class="spinner-border text-white" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <ng-template #intro>
    <div class="page-content survey-star">
      <div class="row">
        <div class="col-md-6">
          <h3>{{ service.formInstance.name }}</h3>
          <h3 *ngIf="service.formInstance.formTemplate.formTemplateCategory.isPAIS">
            {{ service.formInstance.formGroupInstance.formGroupCompany?.name }}
          </h3>
          <h3 *ngIf="service.formInstance.subject">
            {{ service.formInstance.subject.contactLabel(true) }}
          </h3>
          <div [innerHTML]="service.formInstance.introText"></div>
          <button class="btn-deal d-flex gap-1" type="button" (click)="service.startFormInstance()">
            Start Survey<mat-icon svgIcon="arrow-right" />
          </button>
          <div class="scan" *ngIf="service.formInstance.qrCode">
            <div class="qrcode">
              <img [src]="service.formInstance.qrCode" />
            </div>
            <span>Or scan to complete the survey on your device</span>
          </div>
        </div>
        <div class="col-md-6 img-side">
          <img [src]="service.formInstance.introImage" />
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #form>
    <div class="container-fluid">
      <form
        #form="ngForm"
        class="page-content questionnaire-wrap mb-5"
        [formGroup]="service.formGroup"
        novalidate
        (ngSubmit)="service.submitFormInstance()"
      >
        <app-form-completion-template [formTemplate]="service.formInstance.formTemplate" />
      </form>
    </div>
  </ng-template>

  <ng-template #thankyou>
    <div class="page-content survey-star">
      <div class="row">
        <div class="col-md-6">
          <div class="thankyou">
            <div
              *ngIf="!!service.formInstance.formTemplate.onlineThankyouMessage"
              [innerHTML]="service.formInstance.formTemplate.onlineThankyouMessage"
              class="my-4 onlineThankyouMessage"
            ></div>
            <img class="logo" src="assets/images/CFEP-logo.png" alt="cfep-logo" />
            <img
              *ngIf="!!service.formInstance.formGroupInstance.formGroupCompany?.signedLogoUrl"
              class="logo"
              [src]="service.formInstance.formGroupInstance.formGroupCompany?.signedLogoUrl"
              alt="cobranded-logo"
            />
            <span>Thank you for your feedback</span>
            <p>
              If you have questions about this survey, please contact Client Focused Evaluation
              Program (CFEP) Surveys
            </p>
            <div class="social">
              <a href="tel:0338552093">
                <img src="assets/images/phone-icon.png" />
                (03) 3855 2093
              </a>
              <a href="mail:info@cfepsurveys.com.au">
                <img src="assets/images/mail-icon.png" />
                info@cfepsurveys.com.au
              </a>
              <a href="mail:cfepsurveys.com.au">
                <img src="assets/images/globe-icon.png" />
                cfepsurveys.com.au
              </a>
            </div>
            <button
              *ngIf="!service.formInstance.isCFET && !service.formInstance.isSA"
              class="return-bck"
              (click)="service.restartFormInstance()"
            >
              Start new survey <img src="assets/images/return-icon.png" />
            </button>
          </div>
        </div>
        <div class="col-md-6 img-side">
          <img [src]="service.formInstance.introImage" />
        </div>
      </div>
    </div>
  </ng-template>
</div>
