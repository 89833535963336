import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ToastService } from "../toast.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class TokenGuardService {
  constructor(
    private toastService: ToastService,
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { tokenScope: scope, fallbackRoute } = route.data;
    const { token } = route.queryParams;

    const fallbackRoutes = this.router.createUrlTree([fallbackRoute]); // TODO add fallback to error page by default

    if (!scope) {
      return fallbackRoutes;
    }

    if (!token) {
      this.toastService.showError("Reset password link is invalid");
      return fallbackRoutes;
    }

    return new Promise((resolve) => {
      this.authService.validateToken(token, scope).subscribe({
        next: () => resolve(true),
        error: (error) => {
          this.toastService.showError(error.detail.message);
          resolve(fallbackRoutes);
        },
      });
    });
  }
}
