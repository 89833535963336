import { Type } from "class-transformer";
import { User } from "../auth/user.model";
import { BaseModel } from "../base.model";

export class Reviewable extends BaseModel {
  reviewedByUserId?: string;
  @Type(() => User)
  reviewedBy?: User;
  @Type(() => Date)
  reviewedOn?: Date;
}
