import { ErrorHandler, Inject, Injectable, Injector, NgZone } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { ErrorTypeEnum } from "./contract/error-handler.contract";

@Injectable({
  providedIn: "root",
})
/**
 * @description This service is used to show message data for UnprocessableContent errors and them send them to Sentry.
 */
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    @Inject(Injector) private injector: Injector,
    private zone: NgZone,
  ) {
    if (environment.enableSentry) {
      Sentry.init({
        tracePropagationTargets: [
          "localhost",
          "https://eyewatch-api.sparkbusinesstechnology.com.au",
        ],
        dsn: "https://644f762ae1191a00b254b0226d4d0381@o135869.ingest.sentry.io/4505785029951488",
        environment: environment.production ? "Production" : "Development",
        release: environment.buildId,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.05,
      });
    }
  }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  handleError(error: any): void {
    // Handle errors here before sending to Sentry

    const errorCode = error?.statusCode || error?.rejection?.statusCode;
    if (errorCode === ErrorTypeEnum.UnprocessableContent) {
      let errorMessage: string = "";
      if (error.rejection && error.rejection.detail && error.rejection.detail.message) {
        errorMessage = error.rejection.detail.message;
      }

      if (!!errorMessage) {
        this._showError(errorMessage);
      }
    } else {
      this._showError("Something went wrong. Please try again.");
      if (!environment.production) {
        console.error(error);
      }
    }

    // Send error to Sentry
    Sentry.captureException(error.originalError || error);
  }

  private _showError(errorMessage: string): void {
    this.zone.run(() => {
      this.toastrService.error(errorMessage);
    });
  }
}
