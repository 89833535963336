import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FormGroupCompany } from "../../model/forms/form-group-company.model";
import { IQueryFilter, QueryResult } from "../../model/query.filter.class";
import { createUrl, queryToParams } from "../api.util";
import { GenericApi, HasIdOrExternalValueOrExternalIdOrServiceName } from "../generic.api";

// Convenience Type
type T = FormGroupCompany;
type I = FormGroupCompany;

@Injectable({
  providedIn: "root",
})
export class FormGroupCompanyApi extends GenericApi<T> {
  public path = "form-group-company";

  constructor(public httpClient: HttpClient) {
    super(httpClient);
  }

  get = (id: number): Observable<T & HasIdOrExternalValueOrExternalIdOrServiceName<string>> => {
    return this.httpClient.get<T & HasIdOrExternalValueOrExternalIdOrServiceName<string>>(
      createUrl(this.path, id),
    );
  };

  list = (
    query: IQueryFilter,
  ): Observable<QueryResult<T & HasIdOrExternalValueOrExternalIdOrServiceName<string>>> => {
    return this.httpClient.get<
      QueryResult<T & HasIdOrExternalValueOrExternalIdOrServiceName<string>>
    >(createUrl(this.path), {
      params: queryToParams(query),
    });
  };

  getByBenchmarkId = (
    benchmarkId: number,
    query: IQueryFilter,
  ): Observable<QueryResult<FormGroupCompany>> => {
    return this.httpClient.get<QueryResult<FormGroupCompany>>(
      createUrl(this.path, "benchmark", benchmarkId),
      {
        params: queryToParams(query),
      },
    );
  };

  getLogoSignedUrl = (id: number): Observable<string> => {
    return this.httpClient.get<string>(createUrl(this.path, id, "logo-signed-url"));
  };
}
