import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { CareLevel } from "./care-level.model";
import { PractitionerLevel } from "./practitioner-level.model";
import { SpecialityLevel } from "./speciality-level.model";

export class PractitionerType extends BaseModel {
  value: string;
  prefixShort: string;
  prefixLong: string;
  displayLabel: string;
  benchmarkLabel: string;
  @Type(() => Boolean)
  hidden: boolean;
  @Type(() => CareLevel)
  careLevel: CareLevel;
  @Type(() => PractitionerLevel)
  practitionerLevel: PractitionerLevel;
  @Type(() => SpecialityLevel)
  specialityLevel: SpecialityLevel;

  get summary(): string {
    const careLevel = this.careLevel?.label;
    const practitionerLevel = this.practitionerLevel?.benchmarkFilterDisplayLabel;
    const specialityLevel = this.specialityLevel?.label;

    const labels: string[] = [];
    if (careLevel) labels.push(careLevel);
    if (practitionerLevel) labels.push(practitionerLevel);
    if (specialityLevel) labels.push(specialityLevel);

    return labels.join(" - ");
  }
}
