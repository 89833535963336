import { SafeHtml } from "@angular/platform-browser";
import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { QuestionTypeNumberEnum } from "../forms/contract/form-instance-contract";
import { QuestionEnumValueVariant } from "../forms/question-enum-value-variant.model";
import { QuestionGroup } from "../forms/question-group.model";
import { QuestionVariant } from "../forms/question-variant.model";

export class BenchmarkData extends BaseModel {
  id: number;
  mean: number;
  minimum: number;
  fifthPercentile: number;
  tenthPercentile: number;
  lowerQuartile: number;
  median: number;
  upperQuartile: number;
  ninetiethPercentile: number;
  ninetyFifthPercentile: number;
  maximum: number;
  rowCount: number;
  formTemplateId: number;
  questionId: number;
  questionGroupId: number;
  questionVariantId: number;
  questionEnumValueVariantGroupId: number;
  questionEnumValueVariantId: number; // option response value e.g. value for male\female\binary\other
  questionEnumValue: number;
  benchmarkId: number;
  questionType: QuestionTypeNumberEnum;
  order: number;
  @Type(() => QuestionVariant)
  qVariant: QuestionVariant;
  @Type(() => QuestionEnumValueVariant)
  qEnumValueVariant: QuestionEnumValueVariant;
  @Type(() => QuestionGroup)
  questionGroup: QuestionGroup;
}

export type TBenchmarkData = {
  mean: number;
  meanStyle?: string;
  minimum: number;
  minimumStyle?: string;
  fifthPercentile: number;
  fifthPercentileStyle?: string;
  tenthPercentile: number;
  tenthPercentileStyle?: string;
  lowerQuartile: number;
  lowerQuartileStyle?: string;
  median: number;
  medianStyle?: string;
  upperQuartile: number;
  upperQuartileStyle?: string;
  ninetiethPercentile: number;
  ninetiethPercentileStyle?: string;
  ninetyFifthPercentile: number;
  ninetyFifthPercentileStyle?: string;
  maximum: number;
  maximumStyle?: string;
  rowCount: number;
  order: number;
  orderStyle?: string;
  question: SafeHtml;
  questionStyle?: string;
};
