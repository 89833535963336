import { Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { RouterOutlet } from "@angular/router";
import { logger } from "../../util/Logger";
import { slideInAnimation } from "../../util/animations";

const className = "MainTemplateComponent";
declare let ga: Function;

@Component({
  moduleId: module.id,
  animations: [slideInAnimation],
  selector: "app-main-template",
  templateUrl: "main-template.component.html",
  styleUrls: ["main-template.component.scss"],
})
export class MainTemplateComponent implements OnInit {
  year: string = new Date().getFullYear().toString();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this._registerIcons();
  }

  ngOnInit() {
    this.blurNumberInputOnWheel();
  }

  prepareRoute(outlet: RouterOutlet, data: string) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData[data];
  }

  blurNumberInputOnWheel() {
    const signature = className + ".blurNumberInput: ";
    document.addEventListener("wheel", function () {
      if (
        document.activeElement &&
        document.activeElement["type"] &&
        document.activeElement["type"] === "number" &&
        document.activeElement["blur"]
      ) {
        logger.silly(signature + "Prevented mWheel in Number Input");
        document.activeElement["blur"]();
      }
    });
  }

  private _registerIcons() {
    this.matIconRegistry.addSvgIcon(
      "arrow-left",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/arrow-left.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "arrow-right",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/arrow-right.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "chevron-down",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/chevron-down.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "circle-dotted",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/circle-dotted.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "download",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/download.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "funnel",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/funnel.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "home",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/home.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "login",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/login.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "logout",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/logout.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "phone",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/phone.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "rating-1",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/rating-1.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "rating-2",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/rating-2.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "rating-3",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/rating-3.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "rating-4",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/rating-4.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "rating-5",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/rating-5.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "refresh",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/refresh.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "restart",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/restart.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "search-advanced",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/search-advanced.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "search",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/search.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "settings",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/settings.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "survey-completed",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/survey-completed.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "survey-in-progress",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/images/icons/survey-in-progress.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "benchmark-icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/benchmark-icon.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "survey",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/survey.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "thumbsup",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/thumbsup.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "user",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/user.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "warning",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/icons/warning.svg"),
    );
  }
}
