import { plainToInstance } from "class-transformer";
import { merge } from "lodash-es";
import { TAPIListResult, TAPIQuery } from "src/common/contracts/api.contracts";
import { IQueryFilter, QueryResult } from "../model/query.filter.class";

export class BaseService {
  /**
   * @description This method is used to transform the TAPIQuery params to an IQueryFilter.
   * Don't want to force components to specific API implementation
   * TAPIQuery will be output from the components and translated to the structure the API requires for pagination and filtering
   * @param queryParams
   * @returns IQueryFilter
   */
  transformQueryParamsToIQueryFilter(queryParams?: TAPIQuery): IQueryFilter {
    const query: IQueryFilter = new IQueryFilter();

    if (queryParams) {
      if (queryParams.filter) {
        query.filter = queryParams.filter;
      }

      if (queryParams.pagination.sortBy) {
        query.sortBy = queryParams.pagination.sortBy;
      }

      if (queryParams.pagination.order) {
        query.order = queryParams.pagination.order;
      }

      if (queryParams.pagination.limit) {
        query.limit = queryParams.pagination.limit;
      }

      if (queryParams.pagination.skip) {
        query.skip = queryParams.pagination.skip;
      }
    }

    return query;
  }

  /**
   * @description Used when the service query already has a filter and the component needs to add to it.
   * @param componentQuery Query set in the component
   * @param defaultServiceQuery Default query set in the service
   * @returns a query with a combined filter but with the component query taking precedence for all other properties
   */
  mergeQueryFilters(componentQuery: IQueryFilter, defaultServiceQuery: IQueryFilter): IQueryFilter {
    const query: IQueryFilter = new IQueryFilter();

    merge(query.filter, defaultServiceQuery.filter);
    merge(query.filter, componentQuery.filter);
    merge(query.include, defaultServiceQuery.include);
    merge(query.include, componentQuery.include);
    query.order = componentQuery.order || defaultServiceQuery.order;
    query.sortBy = componentQuery.sortBy || defaultServiceQuery.sortBy;
    query.limit = componentQuery.limit || defaultServiceQuery.limit;
    query.skip = componentQuery.skip || defaultServiceQuery.skip;

    return query;
  }

  /**
   * @description This method is used to inflate the model from the data returned from the API.
   * @param model The model to inflate
   * @param data The data to inflate the model with
   * @returns
   * example:
   * return this.inflateModel<Deal, Deal & HasIdOrExternalIdOrServiceName<string>>(Deal, data);
   */
  protected async inflateModel<T, K>(
    model: new () => T,
    data: QueryResult<K>,
  ): Promise<TAPIListResult<T>> {
    try {
      const result: TAPIListResult<T> = { count: 0, rows: [] };
      const modelInstances: T[] = [];

      const rows = "rows" in data ? data.rows : Array.isArray(data) ? (data as unknown as K[]) : [];
      const count = "count" in data ? data.count : rows.length || 0;
      if (rows.length > 0) {
        for (let i = 0; i < rows.length; i++) {
          modelInstances.push(plainToInstance(model, rows[i]));
        }

        result.count = count;
        result.rows = modelInstances;
      }

      return result;
    } catch (error) {
      throw error;
    }
  }
}
