import { Component, Input } from "@angular/core";
import { QuestionTypeEnum } from "src/app/main/model/forms/form-template-question.model";
import { QuestionText } from "src/app/main/model/forms/question-text.model";
import { FormCompletionBaseComponent } from "../form-completion-base.component";

@Component({
  selector: "app-question-text",
  templateUrl: "./question-text.component.html",
  styleUrls: ["./question-text.component.scss"],
})
export class QuestionTextComponent extends FormCompletionBaseComponent {
  @Input() public question: QuestionText;
  public questionType = QuestionTypeEnum;
}
