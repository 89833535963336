<app-public-header></app-public-header>

<div class="welcome-wrap">
  <h3>Welcome!</h3>
  <p>Use your credentials to access</p>
  <form #form="ngForm" class="row g-3" [formGroup]="loginForm" novalidate (submit)="onSubmit()">
    <div class="col-md-12">
      <input
        class="form-control"
        [ngClass]="{
          'is-invalid': (form.submitted || f.email.touched) && f.email.invalid,
          'is-valid': f.email.valid
        }"
        id="email"
        type="email"
        placeholder="Enter your email"
        formControlName="email"
        aria-label="Enter email"
      />
      <div
        *ngIf="f.email.invalid && ((f.email.dirty && f.email.touched) || form.submitted)"
        class="invalid-feedback"
      >
        Invalid email address
      </div>
    </div>
    <div class="col-md-12">
      <input
        class="form-control"
        [ngClass]="{
          'is-invalid': (form.submitted || f.password.touched) && f.password.invalid,
          'is-valid': f.password.valid
        }"
        id="password"
        type="password"
        placeholder="Enter your password"
        formControlName="password"
        aria-label="Enter your password"
      />
      <div
        *ngIf="f.password.invalid && ((f.password.dirty && f.password.touched) || form.submitted)"
        class="invalid-feedback"
      >
        Password is required
      </div>
    </div>
    <div class="col-12">
      <button type="submit" class="btn btn-green">Login</button>
    </div>
    <span>Forgot your password?<a [routerLink]="'/forgot-password'"> Reset here</a></span>
  </form>
</div>
<app-public-footer></app-public-footer>
