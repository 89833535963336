import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/main/services/auth/auth.service";
import { ToastService } from "src/app/main/services/toast.service";
import { emailValidator } from "src/app/main/validators/reactiveFormValidators";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  public isRequestSubmitted: boolean = false;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ["", [Validators.required, emailValidator]],
    });
  }

  onSubmit() {
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.authService.forgot(this.forgotPasswordForm.value).subscribe({
      next: () => {
        this.isRequestSubmitted = true;
      },
      error: (error) => {
        this.toastService.showError(error.detail.message);
      },
    });
  }

  // Helper function
  get f() {
    return this.forgotPasswordForm.controls;
  }
}
