import { PlatformLocation } from "@angular/common";
import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(
    private modalService: NgbModal,
    private location: PlatformLocation,
  ) {
    // closes modal when back button is clicked
    location.onPopState(() => {
      this.modalService.dismissAll();
    });
  }
}
