import { Component, Input } from "@angular/core";

@Component({
  selector: "app-public-header",
  templateUrl: "./public-header.component.html",
  styleUrls: ["./public-header.component.scss"],
})
export class PublicHeaderComponent {
  @Input() cobrandedLogo: string | undefined;
}
