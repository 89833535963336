import { Component, HostBinding, Input } from "@angular/core";
import { FormTemplateQuestion } from "src/app/main/model/forms/form-template-question.model";
import {
  FormTemplateSection,
  SectionDirection,
} from "src/app/main/model/forms/form-template-section.model";
import { hasKey } from "src/app/main/util/object.util";
import { FormTemplateSectionBaseComponent } from "../../form-template-base/form-template-section-base.component";

@Component({
  selector: "app-form-completion-template-section",
  templateUrl: "./form-completion-template-section.component.html",
  styleUrls: ["./form-completion-template-section.component.scss"],
})
export class FormCompletionTemplateSectionComponent extends FormTemplateSectionBaseComponent {
  @HostBinding("class.box") @Input() public isSubSection;

  public sectionDirection = SectionDirection;

  public contentDirection(section: FormTemplateSection) {
    let firstQuestion: FormTemplateQuestion;
    if (!this.isFormTemplateSection(section.formTemplateQuestions[0])) {
      firstQuestion = section.formTemplateQuestions[0];
    } else {
      firstQuestion = section.formTemplateQuestions[0]
        .formTemplateQuestions[0] as FormTemplateQuestion;
    }

    return FormTemplateQuestion.isQuestionRange(firstQuestion)
      ? SectionDirection.Vertical
      : SectionDirection.Horizontal;
  }

  public questionContainer(question: FormTemplateQuestion): string {
    if (FormTemplateQuestion.isQuestionList(question)) {
      return "box";
    } else {
      return "qus-wrap question-row";
    }
  }

  public isFormTemplateSection(
    question: FormTemplateQuestion | FormTemplateSection,
  ): question is FormTemplateSection {
    return hasKey(question, "parentFormTemplateSectionId");
  }
}
