import { FormGroup } from "@angular/forms";
import { FormAnswer } from "./form-answer.model";
import { FormTemplateQuestion } from "./form-template-question.model";

export class QuestionText extends FormTemplateQuestion {
  public maxLength = 2000;

  public collectAnswer(formGroup: FormGroup, isEdit: boolean): FormAnswer[] | null {
    if (formGroup.value[this.getName()] === null) {
      return null;
    }

    const formAnswer = new FormAnswer();
    formAnswer.formTemplateQuestionId = this.id;
    formAnswer[this.getAnswerDataField(isEdit)[0]] = encodeURIComponent(
      formGroup.value[this.getName()],
    );

    return [formAnswer];
  }

  public readDataFromAnswers(answers: Record<number, FormAnswer>, isEdit = false): string {
    if (answers[this.id]) {
      const answer = answers[this.id];
      return answer[this.getAnswerDataField(isEdit)[0]] || "";
    }
    return "";
  }
}
