import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { NotificationsService } from "angular2-notifications";
import { plainToInstance } from "class-transformer";
import * as _ from "lodash";
import { get } from "lodash-es";
import { lastValueFrom } from "rxjs";
import {
  API_ASSOCIATIONS,
  FORMINSTANCE_AND_BENCHMARK_JSON_PATH,
} from "src/common/constants/api.constants";
import { TAPIListResult, TAPIQuery } from "src/common/contracts/api.contracts";
import { apiCallWrapper } from "../api/api.util";
import { FormInstanceApi } from "../api/form-instance.api";
import { HasIdOrExternalValueOrExternalIdOrServiceName } from "../api/generic.api";
import { FormInstance } from "../model/forms/form-instance.model";
import { IDBInclude, IQueryFilter } from "../model/query.filter.class";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: "root",
})
export class FormInstanceService extends BaseService {
  constructor(
    private notifications: NotificationsService,
    private formInstanceApi: FormInstanceApi,
  ) {
    super();
  }

  /**
   * Makes the API call to fetch the form-instance by uuid
   * The output structure is form-instance -> form-template -> sections [-> sections] -> questions.
   *
   * @param {string} uuid the FormInstance uuid to fetch
   * @returns {Promise<FormInstance>} a promise that resolves to the FormInstance
   */
  public getFormInstanceByUUID(uuid: string, isOnline = true): Promise<FormInstance> {
    return new Promise((resolve, reject) => {
      apiCallWrapper(this.formInstanceApi.getByUUID(uuid, isOnline), {
        notificationsService: this.notifications,
        action: "Fetch form-instance",
      }).subscribe({
        next: (result) => {
          const formInstance = plainToInstance(FormInstance, result);
          resolve(formInstance);
        },
        error: (error) => reject(error),
      });
    });
  }

  /**
   * Makes the API call to fetch the form-instance by id
   *
   * @param {string} id the FormInstance id to fetch
   * @returns {Promise<FormInstance>} a promise that resolves to the FormInstance
   */
  public getFormInstanceById(id: string | number): Promise<FormInstance> {
    return new Promise((resolve, reject) => {
      apiCallWrapper(this.formInstanceApi.get(id), {
        notificationsService: this.notifications,
        action: "Fetch form-instance",
      }).subscribe({
        next: (result) => {
          const formInstance = plainToInstance(FormInstance, result);
          resolve(formInstance);
        },
        error: (error) => reject(error),
      });
    });
  }

  public async getFormInstances(query: IQueryFilter): Promise<TAPIListResult<FormInstance>> {
    try {
      const data = await lastValueFrom(
        apiCallWrapper(this.formInstanceApi.list(query), {
          notificationsService: this.notifications,
          action: "Obtaining FormInstances",
        }),
      );

      return this.inflateModel<
        FormInstance,
        FormInstance & HasIdOrExternalValueOrExternalIdOrServiceName<string>
      >(FormInstance, data);
    } catch (error) {
      throw error;
    }
  }

  /**
   * @returns a paginated list of FormInstance and benchmarks data
   */
  public async getFormInstanceByBenchmarkId(
    id: number,
    limit?: number, //TODO: Fix this fudge to get around the fact the API_ASSOCIATIONS.formGroupCompanyAndBenchmark doesn't always return data
    // database is being called with limit in the inner query instead of around the entire query.  Outstanding question raised with Steele
    queryParams?: TAPIQuery,
  ): Promise<TAPIListResult<FormInstance>> {
    try {
      const componentQuery = this.transformQueryParamsToIQueryFilter(queryParams);
      if (limit) componentQuery.limit = limit;

      const defaultQuery: IQueryFilter = new IQueryFilter(
        _.cloneDeep(API_ASSOCIATIONS.formInstanceAndBenchmark),
      );

      const benchmarkInclude: IDBInclude | undefined = get(
        defaultQuery,
        FORMINSTANCE_AND_BENCHMARK_JSON_PATH,
      ) as unknown as IDBInclude;
      if (benchmarkInclude) {
        benchmarkInclude.filter = { id };
      } else {
        Sentry.captureException(
          new Error(
            "FORMINSTANCE_AND_BENCHMARK_JSON_PATH not found in API_ASSOCIATIONS.formInstanceAndBenchmark",
          ),
        );
        throw "Failed to get Benchmark Questionnaire Instance data";
      }

      return await this.getFormInstances(this.mergeQueryFilters(componentQuery, defaultQuery));
    } catch (error) {
      throw error;
    }
  }
}
