import { ErrorHandler, Inject, Injectable, Injector, NgZone } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { ErrorTypeEnum } from "./contract/error-handler.contract";

@Injectable({
  providedIn: "root",
})
/**
 * @description This service is used to show message data for UnprocessableContent errors and them send them to Sentry.
 */
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    @Inject(Injector) private injector: Injector,
    private zone: NgZone,
  ) {
    if (environment.enableSentry) {
      Sentry.init({
        // Trace calls through to the API
        tracePropagationTargets: [environment.endpoint, "localhost"],
        dsn: "https://644f762ae1191a00b254b0226d4d0381@o135869.ingest.sentry.io/4505785029951488",
        environment: environment.production ? "Production" : "Development",
        release: environment.buildId,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: environment.sentryReplayMaskAllText,
            maskAllInputs: environment.sentryReplayMaskAllInputs,
            blockAllMedia: environment.sentryReplayBlockAllMedia,
          }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment.sentryTracesSampleRate,
        // Session Replay
        replaysSessionSampleRate: environment.sentryReplaySessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: environment.sentryReplayOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  handleError(error: any): void {
    // Handle errors here before sending to Sentry

    const errorCode = error?.statusCode || error?.rejection?.statusCode;
    if (errorCode === ErrorTypeEnum.UnprocessableContent) {
      let errorMessage: string = "";
      if (error.rejection && error.rejection.detail && error.rejection.detail.message) {
        errorMessage = error.rejection.detail.message;
      }

      if (!!errorMessage) {
        this._showError(errorMessage);
      }
    } else {
      this._showError("Something went wrong. Please try again.");
      if (!environment.production) {
        console.error(error);
      }
    }

    // Send error to Sentry
    Sentry.captureException(error.originalError || error);
  }

  private _showError(errorMessage: string): void {
    this.zone.run(() => {
      this.toastrService.error(errorMessage);
    });
  }
}
