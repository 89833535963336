import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormInstanceContactService } from "src/app/main/services/forms/form-instance-contact.service";
import { ToastService } from "src/app/main/services/toast.service";

@Component({
  selector: "app-stop-auto-emails",
  templateUrl: "./stop-auto-emails.component.html",
  styleUrls: ["./stop-auto-emails.component.scss"],
})
export class StopAutoEmailsComponent implements OnInit {
  public result: string;

  constructor(
    private formInstanceContactService: FormInstanceContactService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const uuid = this.activatedRoute.snapshot.paramMap.get("uuid");
      if (uuid) {
        const response = await this.formInstanceContactService.stopAutoEmails(uuid);
        this.result = <string>response.message;
      }
    } catch (error) {
      this.toastService.showError("Unable to stop automatic emails. Please try again.");
      throw error;
    }
  }

  // Helper function
}
