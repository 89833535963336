import { Transform, plainToInstance } from "class-transformer";
import { hasKey } from "../../util/object.util";
import { QuestionTypeEnum } from "./form-template-question.model";
import { QuestionList } from "./question-list.model";
import { QuestionRange } from "./question-range.model";
import { QuestionText } from "./question-text.model";

export enum SectionDirection {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

export enum FormTemplateSectionType {
  Question = "Question",
  Text = "Text",
  Introduction = "Introduction",
}

export class FormTemplateSection {
  id: number;
  parentFormTemplateSectionId: number | null;
  order: number;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  value: string;
  description: string;
  // https://www.notion.so/FormTemplate-viewMeta-fb305e5f930a42f097e998cf88cbed6f
  viewMeta: {
    noPageBreaker?: boolean;
    width?: number;
    height?: number;
  } | null;
  formTemplateSectionType: {
    name: FormTemplateSectionType;
    description: string;
  };
  @Transform(({ value: formTemplateQuestions }) => {
    return formTemplateQuestions.map((q) => {
      if (q.parentFormTemplateSectionId !== undefined && q.parentFormTemplateSectionId !== null) {
        return plainToInstance(FormTemplateSection, q);
      } else {
        switch (q.type) {
          case QuestionTypeEnum.Range:
            return plainToInstance(QuestionRange, q);
          case QuestionTypeEnum.List:
            return plainToInstance(QuestionList, q);
          case QuestionTypeEnum.Text:
          case QuestionTypeEnum.ShortText:
            return plainToInstance(QuestionText, q);
          default:
            break;
        }
      }
    });
  })
  formTemplateQuestions: (FormTemplateSection | QuestionRange | QuestionList | QuestionText)[];

  public static isFormTemplateSection<T>(
    section: FormTemplateSection | QuestionRange | QuestionList | QuestionText,
  ): section is FormTemplateSection {
    return hasKey(section, "parentFormTemplateSectionId");
  }

  get questions() {
    return this.formTemplateQuestions.reduce((questionsAcc, questionCurr) => {
      if (FormTemplateSection.isFormTemplateSection(questionCurr)) {
        const sectionQuestions = questionCurr.formTemplateQuestions.reduce(
          (subSectionQAcc, subSectionQCurr) =>
            FormTemplateSection.isFormTemplateSection(subSectionQCurr)
              ? subSectionQAcc
              : [...subSectionQAcc, subSectionQCurr],
          [],
        );
        return [...questionsAcc, ...sectionQuestions];
      } else {
        return [...questionsAcc, questionCurr];
      }
    }, []);
  }
}
