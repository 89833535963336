import { EventEmitter, Injectable } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { Observable, timer } from "rxjs";
import { finalize, switchMap, take } from "rxjs/operators";
import { IJWTPayload } from "../model/auth/auth.model";
import { quickGuid } from "../util/quick-guid";

export type PrevPageRequest = {
  defaultUrl?: string;
  filter?: string | RegExp;
};

@Injectable({
  providedIn: "root",
})
export class SessionService {
  /* Global isLoading property will cause the loading overlay to show */
  isLoading: boolean = false;

  public isLoadingChange = new EventEmitter<boolean>();

  /**
   * @description Broadcasts intent to return to the previous page in the app, defaulting to the supplied string
   */
  public requestPrevPage = new EventEmitter<PrevPageRequest | undefined>();

  /*
    isReady indicates the app has loaded everything needed (including credentials) and is ready to
    proceed. If this is not set, the application should not start.
  */
  isReady: boolean = false;

  /* The purpose of this hold version cofirmations */
  versionNumberLastCheckedDate: Date;

  // If these credentials are present, the user has authenticated.
  credentials: IJWTPayload | null;

  constructor() {}

  init = () => {
    this.isReady = false;
    this.flash = {};
    this.credentials = null;

    this.lockInput(async () => {
      this.isReady = true;
    });
  };

  /*
    The flash scope should be cleared by the next init. It should be used for low-key transitions
    only. It should never be used for long term storage
   */
  flash: Object = {};

  /*
    For the purpose of tracking threads which are locking the input and showing a loading screen
   */
  threads: String[] = [];
  lockInput = async (func: () => Promise<any>) => {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        this.isLoading = true;
        if (this.threads.length === 0) {
          this.isLoadingChange.emit(true);
        }
        const threadId = quickGuid();
        this.threads.push(threadId);
        try {
          await func();
        } catch (error) {
          // Die Silently
          // throw e;
          Sentry.captureException(error);
        } finally {
          this.threads.splice(this.threads.indexOf(threadId), 1);
          if (this.threads.length === 0) {
            this.isLoading = false;
            this.isLoadingChange.emit(false);
          }

          resolve({});
        }
      }, 0);
    });
  };

  lockInputRx = <T>(asyncEntity: Observable<T>): Observable<T> => {
    this.isLoading = true;
    if (this.threads.length === 0) {
      this.isLoadingChange.emit(true);
    }
    const threadId = quickGuid();
    this.threads.push(threadId);

    return timer(0).pipe(
      switchMap(() =>
        asyncEntity.pipe(
          take(1),
          finalize(() => {
            const index = this.threads.indexOf(threadId);
            if (index !== -1) {
              this.threads.splice(index, 1);
            }
            if (this.threads.length === 0) {
              this.isLoading = false;
              this.isLoadingChange.emit(false);
            }
          }),
        ),
      ),
    );
  };
}
