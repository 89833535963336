import { Type } from "class-transformer";
import { PermissionEnum } from "../../../../common/constants/auth.constants";
import { IdentifiableContact } from "../crm/contracts/contact.contract";
import { UserRole } from "./user-role.model";

export class User extends IdentifiableContact {
  @Type(() => UserRole)
  roles: UserRole[];

  /**
   * @description Given 1 or more permissionIds, checks all the users roles to see if they have all the permissions.
   * @param permissionIds
   * @returns true\false
   */
  public hasPermissions(permissionIds: PermissionEnum[]): boolean {
    return permissionIds.every((permissionId) => {
      return this.roles.some((role) => {
        return role.hasPermission(permissionId);
      });
    });
  }

  /**
   * @description Checks if the user has the Operations role
   */
  public get isOperations(): boolean {
    return this.roles.some((role) => {
      return role.isOperations();
    });
  }

  /**
   * @description Checks if the user has the client role
   */
  public get isClient(): boolean {
    return this.roles.some((role) => {
      return role.isClient();
    });
  }

  /**
   * @description Checks if the user has the admin role
   */
  public get isAdmin(): boolean {
    return this.roles.some((role) => {
      return role.isAdmin();
    });
  }

  /**
   * @description Checks if the user has the dro role
   */
  public get isDRO(): boolean {
    return this.roles.some((role) => {
      return role.isDRO();
    });
  }
}
