import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./services/auth/auth-guard.service";
import { TokenGuardService } from "./services/auth/token-guard.service";
import { OptOutComponent } from "./view/admin-client/opt-out/opt-out.component";
import { StopAutoEmailsComponent } from "./view/admin-client/stop-auto-emails/stop-auto-emails.component";
import { ForgotPasswordComponent } from "./view/auth/forgot-password/forgot-password.component";
import { LoginComponent } from "./view/auth/login/login.component";
import { ResetPasswordComponent } from "./view/auth/reset-password/reset-password.component";
import { NotFoundComponent } from "./view/error/not-found/not-found.component";
import { OnlineFormComponent } from "./view/online-form/online-form.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: { tokenScope: "resetPassword", fallbackRoute: "/login" },
    canActivate: [TokenGuardService],
  },
  {
    path: "opt-out/:uuid",
    component: OptOutComponent,
  },
  {
    path: "stop-auto-emails/:uuid",
    component: StopAutoEmailsComponent,
  },
  {
    path: "dashboard",
    canActivate: [AuthGuardService],
    loadChildren: () => import("./view/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "client-dashboard",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./view/dashboard-client/dashboard-client.module").then(
        (m) => m.ClientDashboardModule,
      ),
  },
  {
    path: "admin-dashboard",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./view/dashboard-admin/dashboard-admin.module").then((m) => m.AdminDashboardModule),
  },
  {
    path: "dro-dashboard",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./view/dashboard-dro/dashboard-dro.module").then((m) => m.DRODashboardModule),
  },
  {
    path: "questionnaire/:id",
    component: OnlineFormComponent,
  },
  {
    path: "",
    redirectTo: "dashboard",
  },
  {
    path: "404",
    component: NotFoundComponent,
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
