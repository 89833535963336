import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { FormTemplate } from "./form-template.model";

export class FormTemplateGroup extends BaseModel {
  id: number;
  formGroupId: number;
  formTemplateId: number;
  @Type(() => FormTemplate)
  formTemplate: FormTemplate;
}
