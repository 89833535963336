import { Transform } from "class-transformer";
import { BaseModel } from "../base.model";

export class QuestionEnumValueVariant extends BaseModel {
  id: number;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  value: string;
  @Transform(({ value }) => decodeURIComponent(value || ""))
  description: string;
  qEnumValueId: number;
  qEnumValueVariantGroupId: number;
  variantDeliveryMethodId: number;
  variantLanguageId: number;
}
