<div class="rateing-wrap" [formGroup]="service.formGroup">
  <div
    class="box"
    *ngFor="let range of question.questionRangeGroup.ranges"
    (click)="selectOption(range.value)"
    [ngStyle]="{ cursor: 'pointer' }"
  >
    <ng-container *ngIf="useIcons">
      <div
        class="highlight"
        [class.active]="control.value === range.value"
        [matTooltip]="range.description"
      >
        <mat-icon [svgIcon]="'rating-' + range.value" class="align-middle" />
      </div>
    </ng-container>
    <ng-container *ngIf="!useIcons">
      <input
        #input
        type="radio"
        [formControlName]="question.getName()"
        [value]="range.value"
        class="align-middle me-1"
      />
      <span>{{ range.description }}</span>
    </ng-container>
  </div>
</div>
