import { BaseModel } from "../base.model";
import { BenchmarkEntityEnum } from "./contract/benchmark-contract";

export class BenchmarkRemovedData extends BaseModel {
  id: number;
  entityType: BenchmarkEntityEnum;
  entityId: number;
  description: string;
  benchmarkId: number;
}
