import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { FormInstance } from "../forms/form-instance.model";
import { BenchmarkData } from "./benchmark-data.model";
import { BenchmarkRemovedData } from "./benchmark-removed-data.model";

export class Benchmark extends BaseModel {
  id: number;
  questionnairesCompletedCount: number;
  practitionerCount: number;
  organisationCount: number;
  removedFormGroupInstancesCount: number;
  removedFormInstancesCount: number;
  minimumResponses: number;
  surveys: number;
  footer: string;
  @Type(() => Date)
  dataStartDate: Date;
  @Type(() => Date)
  dataEndDate: Date;
  @Type(() => BenchmarkData)
  benchmarkData: BenchmarkData[];
  @Type(() => BenchmarkRemovedData)
  benchmarkRemovedData: BenchmarkRemovedData[];
  @Type(() => FormInstance)
  formInstances: FormInstance[];
}
