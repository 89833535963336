import { Type, instanceToPlain, plainToInstance } from "class-transformer";
import { Company } from "../crm/company.model";
import { Contact } from "../crm/contact.model";
import { DocumentTypeEnum } from "../document/contracts/document-type.contract";
import { Document } from "../document/document.model";
import { FormGroupInstanceDocument } from "../document/form-group-instance-document.model";
import { WorkflowStep } from "../workflow/workflow-step.model";
import { FormInstanceType } from "./contract/form-instance-contract";
import { FormGroupOfflineResponses } from "./form-group-offline-responses.model";
import { FormGroup } from "./form-group.model";
import { FormInstanceContact, FormInstanceContactType } from "./form-instance-contact.model";
import { FormInstance } from "./form-instance.model";
import { FormTemplateGroup } from "./form-template-group.model";

export class FormGroupInstance extends FormGroup {
  uuid: string;
  @Type(() => Boolean)
  readyToSend: boolean;
  @Type(() => Boolean)
  isReportSent: boolean;
  @Type(() => Date)
  reportSentDate: Date;
  @Type(() => Boolean)
  isComplete: boolean;
  @Type(() => Boolean)
  isDeleted: boolean;
  @Type(() => Boolean)
  isPracticeOnly: boolean;
  @Type(() => Boolean)
  isClosed: boolean;
  minResponsesForAbrv: number;
  minResponsesForFull: number;
  @Type(() => Boolean)
  haveMinimumResponsesNotBeenReceived: boolean;
  @Type(() => Boolean)
  hasSentMinThresholdReachedEmail: boolean;
  @Type(() => Boolean)
  hasSentPerctThresholdReachedEmail: boolean;
  rurality: string;
  FTE: number;
  surveySentDate: string;
  @Type(() => Date)
  surveyReceivedDate: Date;
  @Type(() => Date)
  commentsCheckedDate: Date;
  @Type(() => Date)
  closedDate: Date;
  modality: string;
  @Type(() => Company)
  formGroupCompany: Company | undefined;
  dealExternalId: string;
  notes: string;
  formGroupId: number;
  @Type(() => FormGroupInstanceDocument)
  formGroupInstanceDocuments: FormGroupInstanceDocument[];
  @Type(() => FormInstance)
  formInstances: FormInstance[];
  @Type(() => FormGroupOfflineResponses)
  formGroupOfflineResponses: FormGroupOfflineResponses[];
  @Type(() => WorkflowStep)
  workflowStep: WorkflowStep;
  formInstancesPrinted: number = 0;

  /**
   * Checks to see if the survey is closed, deleted, or complete
   */
  public get isSurveyOpen(): boolean {
    return !this.isClosed && !this.isDeleted && !this.isComplete;
  }

  public get workflowStepName(): string {
    return this.workflowStep && this.workflowStep.name ? this.workflowStep.name : "Unknown";
  }

  public get hasFormInstances(): boolean {
    return this.formInstances && this.formInstances.length > 0;
  }

  /**
   * @description Get the FormInstance for a given formInstanceId for this formGroupInstance
   * @param formInstanceId
   * @returns
   */
  public getFormInstance(formInstanceId: number): FormInstance | undefined {
    return this.formInstances.find(
      (formInstance: FormInstance) => formInstance.id === formInstanceId,
    );
  }

  /**
   * @description get all form TemplateInstances for a given formTemplateId
   * @param formTemplateId
   */
  public getFormInstances(formTemplateId: number): FormInstance[] {
    return this.formInstances.filter(
      (formInstance: FormInstance) => formInstance.formTemplateId === formTemplateId,
    );
  }

  /**
   * @description Get all formInstances for the given externalContactId
   * @param externalContactId
   * @returns
   */
  public getFormInstancesByExternalContactId(externalContactId: string): FormInstance[] {
    return this.formInstances.filter((formInstance) => {
      const subjectContact = formInstance.subject;
      return subjectContact && subjectContact.externalId === externalContactId;
    });
  }

  /**
   * Forms instances are created for each contact.  This method returns unique formInstances for each contact
   * @returns
   */
  public get uniqueFormInstancesBySubjectExternalContact(): FormInstance[] {
    return this.formInstances.reduce((unique: FormInstance[], formInstance: FormInstance) => {
      if (
        formInstance.subject &&
        !unique.find((existingFormInstance: FormInstance) => {
          const existingContact = existingFormInstance.subject;
          const newContact = formInstance.subject;
          if (existingContact && newContact) {
            return existingContact.externalId === newContact.externalId;
          } else {
            return true;
          }
        })
      ) {
        unique.push(formInstance);
      }
      return unique;
    }, []);
  }

  get knownFormInstances(): FormInstance[] {
    return this.uniqueFormInstancesBySubjectExternalContact.filter(
      (formInstance) => !!formInstance.subject,
    );
  }

  /**
   * @description get anonymous formInstance for this formGroupInstance.  There can be only one, and only for non MSF
   */
  get anonymousFormInstance(): FormInstance | undefined {
    if (!this.hasAnonymousFormInstance) return undefined;

    return this.formInstances.find(
      (formInstance: FormInstance) => formInstance.type === FormInstanceType.anonymous,
    );
  }

  /**
   * @description Get the FormInstance Url for a given formInstanceId for this formGroupInstance
   * @param formInstanceId
   * @returns
   */
  public formInstanceUrl(formInstanceId: number): string {
    const formInstance = this.getFormInstance(formInstanceId);

    return formInstance?.url.toString() || "";
  }

  /**
   *
   * @returns only formGroupInstanceDocuments that have been converted - excluding reports (this would include formInstances as they are already PDFs)
   */
  public get convertedDocuments(): FormGroupInstanceDocument[] {
    return this.formGroupInstanceDocuments.filter(
      (formGroupInstanceDocument) =>
        !!formGroupInstanceDocument.convertedLocation &&
        formGroupInstanceDocument.document.documentType.id !== DocumentTypeEnum.Report,
    );
  }

  /**
   * @description Get unique formGroupInstanceDocuments for this formGroupInstance based on documentId
   */
  public get uniqueConvertedDocuments(): FormGroupInstanceDocument[] {
    return this.convertedDocuments.reduce(
      (
        unique: FormGroupInstanceDocument[],
        formGroupInstanceDocument: FormGroupInstanceDocument,
      ) => {
        if (
          !unique.find((t: FormGroupInstanceDocument) => {
            return t.document.id === formGroupInstanceDocument.document.id;
          })
        ) {
          unique.push(formGroupInstanceDocument);
        }
        return unique;
      },
      [],
    );
  }

  /**
   * @description Checks if a report has been generated for this formGroupInstance
   * @param formInstanceType - not provided if we are checking that any report has been generated
   * @param practitionerExternalId - not provided for practice only formGroupInstances
   */
  public hasGeneratedReport(
    formInstanceType?: FormInstanceType,
    practitionerExternalId?: string,
  ): boolean {
    if (formInstanceType) {
      return !!this.getGeneratedReport(formInstanceType, practitionerExternalId);
    } else {
      return !!this.formGroupInstanceDocuments.find(
        (formGroupInstanceDocument) =>
          formGroupInstanceDocument.document.documentType.id === DocumentTypeEnum.Report &&
          !!formGroupInstanceDocument.convertedLocation,
      );
    }
  }

  /**
   * @description Get the generated report for this formGroupInstance
   * @param formInstanceType
   * @param practitionerExternalId
   * @returns
   */
  public getGeneratedReport(
    formInstanceType: FormInstanceType,
    practitionerExternalId?: string,
  ): FormGroupInstanceDocument | undefined {
    let formGroupInstanceDocument: FormGroupInstanceDocument | undefined;

    switch (formInstanceType) {
      case FormInstanceType.practice:
        //there will only be one form Instance for a practice survey
        formGroupInstanceDocument = this.formGroupInstanceDocuments.find(
          (formGroupInstanceDocument) =>
            formGroupInstanceDocument.document.documentType.id === DocumentTypeEnum.Report &&
            !!formGroupInstanceDocument.convertedLocation,
        );
        break;
      case FormInstanceType.anonymous:
        //find the anonymous form instance
        formGroupInstanceDocument = this.formGroupInstanceDocuments.find(
          (formGroupInstanceDocument) =>
            formGroupInstanceDocument.document.documentType.id === DocumentTypeEnum.Report &&
            !!formGroupInstanceDocument.convertedLocation &&
            formGroupInstanceDocument.formInstanceId === this.anonymousFormInstance?.id,
        );
        break;
      case FormInstanceType.known:
        //find the formInstance for the given practitionerExternalId
        formGroupInstanceDocument = this.formGroupInstanceDocuments.find(
          (formGroupInstanceDocument) =>
            formGroupInstanceDocument.contactExternalId === practitionerExternalId &&
            formGroupInstanceDocument.document.documentType.id === DocumentTypeEnum.Report &&
            !!formGroupInstanceDocument.convertedLocation,
        );
        break;
    }
    return formGroupInstanceDocument;
  }

  /**
   * @description Check to see if any instances have been created for type Anonymous
   */
  public get hasAnonymousFormInstance(): boolean {
    if (!this.hasFormInstances) {
      return false;
    }
    return this.formInstances.some(
      (formInstance: FormInstance) => formInstance.type === FormInstanceType.anonymous,
    );
  }

  /**
   * @description Get the minimum number of responses required for abreviated report at the FormGroupInstance Level
   */
  public get minResponsesForAbrvForFormGroup(): number {
    return this.minResponsesForAbrv * (this.FTE || 1);
  }
  /**
   * @description Get formGroupInstanceDocument ids
   */
  public get documentIds(): number[] {
    let returnIds: number[] = [];
    if (this.formGroupInstanceDocuments) {
      returnIds = this.formGroupInstanceDocuments.map(
        (formGroupInstanceDocument: FormGroupInstanceDocument) => {
          return formGroupInstanceDocument.document.id;
        },
      );
    }
    return returnIds;
  }

  /**
   * @description Get total number of required responses for full report for all contacts
   */
  public get minResponsesForFullAnonymousForFormTemplate(): number {
    let returnValue = 0;

    if (this.hasFormInstances) {
      this.formInstances.forEach((formInstance: FormInstance) => {
        returnValue +=
          formInstance.type === FormInstanceType.anonymous ? formInstance.minResponsesForFull : 0;
      });
    }

    return returnValue;
  }

  /**
   * @description Get total number of required responses for full report for a given contact
   * If no contact exists and the formGroupInstance is practice only, return the total number of required responses for full report for 1 contact
   * @param externalId
   * @param formInstanceId Optional formInstanceId to filter by
   * @returns
   */
  public minResponsesForFullPerContact(externalId: string, formInstanceId?: number): number {
    let returnValue = 0;
    let filteredFormInstances: FormInstance[] = [];

    if (this.hasFormInstances) {
      filteredFormInstances = this.formInstances.filter((formInstance: FormInstance) => {
        if (formInstance.type === FormInstanceType.anonymous) return;

        let requestedFormInstance: FormInstance | undefined;
        if (!formInstanceId || (formInstanceId && formInstance.id === formInstanceId)) {
          requestedFormInstance = formInstance;
        }

        if (this.isPracticeOnly) {
          return true;
        }

        if (requestedFormInstance) {
          return !!requestedFormInstance.formInstanceContacts.find(
            (formInstanceContact: FormInstanceContact) =>
              formInstanceContact.externalId === externalId,
          );
        } else {
          return false;
        }
      });

      filteredFormInstances.forEach((formInstance: FormInstance) => {
        returnValue += formInstance.minResponsesForFull;
      });
    }

    return returnValue;
  }

  /**
   * @description Get total number of required responses for abreviated report for a given contact
   * If no contact exists and the formGroupInstance is practice only, return the total number of required responses for full report for 1 contact
   * @param externalId
   * @param formInstanceId Optional formInstanceId to filter by
   * @returns
   */
  public minResponsesForAbrvPerContact(externalId: string, formInstanceId?: number): number {
    let returnValue = 0;
    let filteredFormInstances: FormInstance[] = [];

    if (this.hasFormInstances) {
      filteredFormInstances = this.formInstances.filter((formInstance: FormInstance) => {
        let requestedFormInstance: FormInstance | undefined;
        if (!formInstanceId || (formInstanceId && formInstance.id === formInstanceId)) {
          requestedFormInstance = formInstance;
        }

        if (this.isPracticeOnly) {
          return true;
        }

        if (requestedFormInstance) {
          return !!requestedFormInstance.formInstanceContacts.find(
            (formInstanceContact: FormInstanceContact) =>
              formInstanceContact.externalId === externalId,
          );
        } else {
          return false;
        }
      });

      filteredFormInstances.forEach((formInstance: FormInstance) => {
        returnValue += formInstance.minResponsesForAbrv;
      });
    }

    return returnValue;
  }

  /**
   * @description Get unique formInstanceContact externalIds for all formInstances in the formGroupInstance for contacts that are
   * the subject of the formGroupInstance
   */
  public get formInstanceContactExternalIds(): string[] {
    let returnIds: string[] = [];
    if (this.hasFormInstances && !this.isPracticeOnly) {
      for (let x = 0; x < this.formInstances.length; x++) {
        if (this.formInstances[x].type === FormInstanceType.known) {
          const subjectContact = this.formInstances[x].formInstanceContacts.find(
            (formInstanceContact: FormInstanceContact) =>
              formInstanceContact.type === FormInstanceContactType.Subject,
          );
          if (!!subjectContact && !returnIds.includes(subjectContact.externalId)) {
            returnIds.push(subjectContact.externalId);
          }
        }
      }
    }
    return returnIds;
  }

  /**
   * @description Create a new FormGroupInstanceDocument for each id in the array (creates skeleton objects)
   * @param ids Array of document ids
   */
  public createFormGroupInstanceDocuments(ids: number[]): void {
    this.formGroupInstanceDocuments = ids.map((id: number) => {
      const newDocument = new Document();
      newDocument.id = id;
      const newFormGroupInstanceDocument = new FormGroupInstanceDocument();
      newFormGroupInstanceDocument.document = newDocument;
      return newFormGroupInstanceDocument;
    });
  }

  /**
   * @description (Re)Create a new FormInstance for formTemplateGroup and each contact in the array (creates skeleton objects)
   * e.g. if there are 2 formTemplateGroups and 3 contacts, this will create 6 FormInstances
   * @param contacts
   * @param formTemplateGroups
   */
  public createFormInstances(
    contacts: Contact[] | FormInstanceContact[],
    formTemplateGroups: FormTemplateGroup[],
  ): void {
    this.formInstances = [];
    for (
      let formTemplateGroupIndex = 0;
      formTemplateGroupIndex < formTemplateGroups.length;
      formTemplateGroupIndex++
    ) {
      if (this.isPracticeOnly) {
        //creating formInstances for practice only
        const newFormInstance = plainToInstance(FormInstance, {
          ...instanceToPlain(formTemplateGroups[formTemplateGroupIndex].formTemplate),
          type: FormInstanceType.practice,
          minResponsesForFull:
            this.minResponsesForFull -
            (this.FTE || 1) *
              formTemplateGroups[formTemplateGroupIndex].formTemplate.minResponsesForFull,
          minResponsesForAbrv:
            this.minResponsesForAbrv -
            (this.FTE || 1) *
              formTemplateGroups[formTemplateGroupIndex].formTemplate.minResponsesForAbrv,
          formTemplateId: formTemplateGroups[formTemplateGroupIndex].formTemplate.id,
        });
        this.formInstances.push(newFormInstance);
      } else {
        for (let contactIndex = 0; contactIndex < contacts.length; contactIndex++) {
          const newFormInstance = plainToInstance(
            FormInstance,
            instanceToPlain({
              ...formTemplateGroups[formTemplateGroupIndex].formTemplate,
              formInstanceContacts: [],
              type: FormInstanceType.known,
              formTemplateId: formTemplateGroups[formTemplateGroupIndex].formTemplate.id,
            }),
          );

          const newFormInstanceContact = plainToInstance(
            FormInstanceContact,
            instanceToPlain({ ...contacts[contactIndex], type: FormInstanceContactType.Subject }),
          );
          newFormInstance.formInstanceContacts.push(newFormInstanceContact);

          this.formInstances.push(newFormInstance);
        }
      }

      // for non-MSF, if not practice only and FTE > number of formInstances, create anonymous formInstance
      if (
        !this.isMSF &&
        !this.isPracticeOnly &&
        (this.FTE || 1) >
          this.getFormInstances(formTemplateGroups[formTemplateGroupIndex].formTemplate.id).length
      ) {
        const newFormInstance = plainToInstance(FormInstance, {
          ...instanceToPlain(formTemplateGroups[formTemplateGroupIndex].formTemplate),
          type: FormInstanceType.anonymous,
          minResponsesForFull:
            ((this.FTE || 1) -
              this.getFormInstances(formTemplateGroups[formTemplateGroupIndex].formTemplate.id)
                .length) *
            formTemplateGroups[formTemplateGroupIndex].formTemplate.minResponsesForFull,
          minResponsesForAbrv:
            ((this.FTE || 1) -
              this.getFormInstances(formTemplateGroups[formTemplateGroupIndex].formTemplate.id)
                .length) *
            formTemplateGroups[formTemplateGroupIndex].formTemplate.minResponsesForAbrv,
        });
        this.formInstances.push(newFormInstance);
      }
    }
  }

  /**
   * @description Checks if a given contact is participating.  Search either by externalId or email
   * @param externalId
   * @param email
   */
  public isParticipantIncluded(externalId?: string, email?: string): boolean {
    let returnValue = false;

    if (this.formInstances && this.formInstances.length > 0) {
      return this.formInstances.some((formInstance: FormInstance) => {
        return !!formInstance.formInstanceContacts.find(
          (formInstanceContact: FormInstanceContact) =>
            externalId
              ? formInstanceContact.externalId === externalId
              : formInstanceContact.email === email,
        );
      });
    }
    return returnValue;
  }

  /**
   * Checks to see if an SMC has been attached to this formGroupInstance
   */
  public get hasSMC(): boolean {
    for (let x = 0; x < this.formInstances.length; x++) {
      const formInstanceContactSMC = this.formInstances[x].formInstanceContacts.find(
        (formInstanceContact) => formInstanceContact.type === FormInstanceContactType.SMC,
      );
      if (formInstanceContactSMC) {
        return true;
      }
    }
    return false;
  }

  /**
   * @description checks if any formInstances are CFET
   */
  public get hasCFET(): boolean {
    if (!this.formInstances) return false;

    return this.formInstances.some((formInstance) => {
      return formInstance.isCFET;
    });
  }

  /**
   * @description checks if any formInstances are SA
   */
  public get hasSA(): boolean {
    if (!this.formInstances) return false;

    return this.formInstances.some((formInstance) => {
      return formInstance.isSA;
    });
  }

  public get hasOfflineResponses(): boolean {
    return this.formGroupOfflineResponses && this.formGroupOfflineResponses.length > 0;
  }

  /**
   * @description returns an array of FormGroupOfflineResponses with receivedDate converted to UTC timestamp
   */
  public get formGroupOfflineResponsesAsUTC(): FormGroupOfflineResponses[] {
    if (!this.hasOfflineResponses) return [];

    return this.formGroupOfflineResponses.map((formGroupOfflineResponse) => {
      return {
        id: formGroupOfflineResponse.id,
        formGroupInstanceId: this.id,
        incomplete: formGroupOfflineResponse.incomplete,
        received: formGroupOfflineResponse.received,
        receivedDate: Date.parse(new Date(formGroupOfflineResponse.receivedDate).toUTCString()),
      };
    });
  }

  /**
   * @description checks if there are any formInstances with answers
   */
  public get hasAnswers(): boolean {
    if (!this.formInstances) return false;
    return this.formInstances.some((formInstance) => formInstance.hasAnswers());
  }
}
