import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { DocumentLevelEnum } from "./contracts/document.contract";
import { DocumentType } from "./document-type.model";

export class Document extends BaseModel {
  id: number;
  name: string;
  location: string;
  @Type(() => DocumentType)
  documentType: DocumentType;
  documentLevel: DocumentLevelEnum;
}
