import { Type } from "class-transformer";
import { BaseModel } from "../base.model";

export class PractitionerLevel extends BaseModel {
  value: string;
  label: string;
  formInstanceDisplayLabel: string;
  benchmarkFilterDisplayLabel: string;
  benchmarkLabel: string;
  @Type(() => Boolean)
  hidden: boolean;
  displayOrder: number;
}
