import { Component } from "@angular/core";
import {
  FormCompletionService,
  FormCompletionStates,
} from "../../services/form-completion/form-completion.service";

@Component({
  selector: "app-online-form",
  templateUrl: "./online-form.component.html",
  styleUrls: ["./online-form.component.scss"],
  providers: [FormCompletionService],
})
export class OnlineFormComponent {
  public states = FormCompletionStates;

  constructor(private formCompletionService: FormCompletionService) {}

  // Helper function
  get service() {
    return this.formCompletionService;
  }
}
