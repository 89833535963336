import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { FormGroupInstance } from "./form-group-instance.model";

export class FormGroupCompany extends BaseModel {
  id: number;
  externalId: string;
  name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  postCode: string;
  country: string;
  postalAddress: string;
  postalAddressCity: string;
  postalAddressPostCode: string;
  postalAddressState: string;
  clientCategory: string;
  orgId: string;
  rurality: string;
  @Type(() => FormGroupInstance)
  formGroupInstances: FormGroupInstance;
  logo: string;
}
