type IUnknownQueryFilter = Record<string, unknown>;

export const PageSizeOptions: Array<number> = [10, 15, 20, 30, 50];

export type IQueryFilterCriteria = Record<string, unknown>;

export class IQueryFilter<T = IUnknownQueryFilter> {
  constructor(defaults?: Partial<IQueryFilter<T>>) {
    Object.assign(this, defaults);
  }

  skip: number = 0;
  limit: number = PageSizeOptions[0];
  sortBy: string;
  order: string = "asc";
  filter: IQueryFilterCriteria = {};
  include: Array<string | IDBInclude> = [];

  getPageNumber = () => this.skip / this.limit + 1;
  setPageNumber = (page: number) => (this.skip = this.limit * (page - 1));
}

export class QueryResult<T = IUnknownQueryFilter> {
  constructor(defaults?: Partial<QueryResult<T>>) {
    Object.assign(this, defaults);
  }

  rows: T[] = [];
  count: number = 0;
  query?: IQueryFilter<T>;

  setQuery = (query: IQueryFilter<T>) => (this.query = query);
  getPageCount = () =>
    !this.query ? 1 : this.count === 0 ? 0 : Math.ceil(this.count / this.query.limit);
}

export interface IDBInclude {
  name: string;
  required?: boolean;
  filter?: IQueryFilterCriteria;
  include?: undefined | Array<string | IDBInclude>;
}
