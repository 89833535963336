import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { RolesEnum } from "./contracts/role.contract";
import { UserPermission } from "./user-permission.model";

export class UserRole extends BaseModel {
  id: number;
  name: string;
  description: string;
  @Type(() => UserPermission)
  permissions: UserPermission[];

  public hasPermission(permissionId: number): boolean {
    return this.permissions.some((permission) => {
      return permission.id === permissionId;
    });
  }

  public isOperations(): boolean {
    return this.id === RolesEnum.Operations;
  }

  public isClient(): boolean {
    return this.id === RolesEnum.Client;
  }

  public isAdmin(): boolean {
    return this.id === RolesEnum.Admin;
  }

  public isDRO(): boolean {
    return this.id === RolesEnum.DRO;
  }
}
