import { Injectable } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { lastValueFrom } from "rxjs";
import { TResultAndMessage } from "src/common/contracts/api.contracts";
import { apiCallWrapper } from "../../api/api.util";
import { FormInstanceContactApi } from "../../api/forms/form-instance-contact.api";
import { BaseService } from "../base.service";

@Injectable({
  providedIn: "root",
})
/**
 * @description This service is used to manage FormInstanceContact API.
 */
export class FormInstanceContactService extends BaseService {
  constructor(
    private notifications: NotificationsService,
    private formInstanceContactApi: FormInstanceContactApi,
  ) {
    super();
  }

  public async optOut(uuid: string): Promise<TResultAndMessage> {
    try {
      const data = await lastValueFrom(
        apiCallWrapper(this.formInstanceContactApi.optOut(uuid), {
          notificationsService: this.notifications,
          action: "FormInstanceContact Opting out",
        }),
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  public async stopAutoEmails(uuid: string): Promise<TResultAndMessage> {
    try {
      const data = await lastValueFrom(
        apiCallWrapper(this.formInstanceContactApi.stopAutoEmails(uuid), {
          notificationsService: this.notifications,
          action: "FormInstanceContact Stopping Auto Emails",
        }),
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
