import { Injectable } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { lastValueFrom } from "rxjs";
import { TAPIListResult, TAPIQuery } from "src/common/contracts/api.contracts";
import { apiCallWrapper } from "../../api/api.util";
import { FormGroupCompanyApi } from "../../api/forms/form-group-company.api";
import { HasIdOrExternalValueOrExternalIdOrServiceName } from "../../api/generic.api";
import { FormGroupCompany } from "../../model/forms/form-group-company.model";
import { BaseService } from "../base.service";

@Injectable({
  providedIn: "root",
})
export class FormGroupCompanyService extends BaseService {
  constructor(
    private notifications: NotificationsService,
    private formGroupCompanyApi: FormGroupCompanyApi,
  ) {
    super();
  }

  /**
   * @description Get the signed logo URL for the company
   * @param id company id
   * @returns
   */
  async getLogoSignedUrl(id: number): Promise<string> {
    try {
      return await lastValueFrom(
        apiCallWrapper(this.formGroupCompanyApi.getLogoSignedUrl(id), {
          notificationsService: this.notifications,
          action: "Obtaining logo signed URL",
        }),
      );
    } catch (error) {
      throw error;
    }
  }

  async getFormGroupCompaniesByBenchmarkId(
    benchmarkId: number,
    query: TAPIQuery,
  ): Promise<TAPIListResult<FormGroupCompany>> {
    try {
      const componentQuery = this.transformQueryParamsToIQueryFilter(query);

      const data = await lastValueFrom(
        apiCallWrapper(this.formGroupCompanyApi.getByBenchmarkId(benchmarkId, componentQuery), {
          notificationsService: this.notifications,
          action: "Obtaining FormGroupCompany by BenchmarkId data",
        }),
      );

      return this.inflateModel<
        FormGroupCompany,
        FormGroupCompany & HasIdOrExternalValueOrExternalIdOrServiceName<string>
      >(FormGroupCompany, data);
    } catch (error) {
      throw error;
    }
  }
}
