import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { QuestionRangeGroupWeighting } from "./question-range-group-weighting.model";

export class QuestionRangeGroup extends BaseModel {
  id: number;
  name: string;
  description: string;
  @Type(() => QuestionRangeGroupWeighting)
  qRangeGroupWs: QuestionRangeGroupWeighting[];
}
