import { Type } from "class-transformer";
import { FormGroupCompanyService } from "../../services/forms/form-group-company.service";
import { BaseModel } from "../base.model";

export class Company extends BaseModel {
  id: number;
  externalId: string;
  name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  postCode: string;
  country: string;
  postalAddress: string;
  postalAddressCity: string;
  postalAddressPostCode: string;
  postalAddressState: string;
  phone: string;
  clientCategory: string; //TODO: enum
  orgId: string;
  isArchived: boolean;
  rurality: string;
  onlineLogo: string;
  offlineLogo: string;
  signedLogoUrl: string;
  @Type(() => Boolean)
  isFromCRM: boolean;

  public get fullPremiseAddress(): string {
    return `${!!this.address ? this.address + ", " : ""}${
      !!this.address2 ? this.address2 + ", " : ""
    }${!!this.city ? this.city + ", " : ""}${!!this.state ? this.state + ", " : ""}${
      !!this.postCode ? this.postCode + ", " : ""
    }${!!this.country ? this.country : ""}`.replace(/,\s*$/, "");
  }

  public get fullPostalAddress(): string {
    return `${!!this.postalAddress ? this.postalAddress + ", " : ""}${
      !!this.postalAddressCity ? this.postalAddressCity + ", " : ""
    }${!!this.postalAddressPostCode ? this.postalAddressPostCode + ", " : ""}${
      !!this.postalAddressState ? this.postalAddressState : ""
    }`.replace(/,\s*$/, "");
  }

  /**
   * @description Sets the signed logo URL for the company via an API call
   * @param formGroupCompanyService
   */
  public async setSignedLogoUrl(formGroupCompanyService: FormGroupCompanyService): Promise<void> {
    if (this.onlineLogo) {
      this.signedLogoUrl = await formGroupCompanyService.getLogoSignedUrl(this.id);
    }
  }
}
