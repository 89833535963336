import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/main/services/auth/auth.service";
import { ToastService } from "src/app/main/services/toast.service";
import {
  passwordValidator,
  validateConfirmPassword,
} from "src/app/main/validators/reactiveFormValidators";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  private resetToken: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    const { token } = this.route.snapshot.queryParams;

    this.resetToken = token;

    this.resetPasswordForm = this.formBuilder.group({
      password: ["", [Validators.required, passwordValidator]],
      confirmPassword: ["", [Validators.required]],
    });
  }

  onSubmit() {
    if (!validateConfirmPassword(this.f.confirmPassword)) {
      this.toastService.showError(
        "The passwords you entered are not identical. Please try again and enter your new password",
      );
      this.resetPasswordForm.reset();
      return;
    }

    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.authService
      .reset({
        password: this.resetPasswordForm.value.password,
        resetToken: this.resetToken,
      })
      .subscribe({
        next: () => {
          this.toastService.showSuccess("You have successfully updated your password");
          this.router.navigate(["/login"]);
        },
        error: (error) => {
          this.toastService.showError(error.detail.message);
          this.router.navigate(["/login"]);
        },
      });
  }

  // Helper function
  get f() {
    return this.resetPasswordForm.controls;
  }
}
